import { useContext } from 'react';
import moment from 'moment';
import PlusCircleIcon from '@components/deprecatedTookit/icons/fa/regular/PlusCircleIcon';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { appUIActionContext } from '@client/components/App/contexts';
import { toggleAppModal } from '@client/state/ui/actions';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast/component';
import DropdownSelectInput from '@client/components/DropdownSelectInput/component';
import Department from '@client/_blessed/store/entities/departments/model';
import InvoicingRulesModal, { type InvoicingRulesModalProps } from './components/InvoicingRulesModal/component';
import { type AccessorialRuleGroup } from './container';
import { updateActiveAccessorialRuleGroup } from './actions';
import {
  UPDATE_ACTIVE_REQUEST,
  UPDATE_ACTIVE_SUCCESS,
  UPDATE_ACTIVE_ERROR,
  DELETE_ACTIVE_REQUEST,
  DELETE_ACTIVE_SUCCESS,
  DELETE_ACTIVE_ERROR
} from './reducer';
import './style.css';

interface Option {
  name: string;
  disabled: boolean;
  value: string;
  icon: string;
}

function deleteCustomRuleGroup(ruleGroupUuid: string): any {
  const url = api(`/accessorial-rule-groups/${ruleGroupUuid}`);
  const opts = { method: 'DELETE' };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

function removeConsigneeFromGlobalRuleGroup(cneeUUID: string, ruleGroupUuid: string): any {
  const url = api(`/accessorial-rule-groups/departments`);
  const payload = { [cneeUUID]: ruleGroupUuid };
  const body = JSON.stringify(payload);
  const headers = { 'Content-Type': 'application/json' };
  const opts = { method: 'DELETE', body, headers };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

interface ARAccessorialFeeAttributes {
  feeType: string;
  name: string;
}

export interface ARAccessorialFee {
  id: string;
  attributes: ARAccessorialFeeAttributes;
}

interface InvoicingRulesProps {
  arAccessorialFees: ARAccessorialFee[];
  accessorialRuleGroup?: AccessorialRuleGroup | null | undefined;
  availableRuleGroups: AccessorialRuleGroup[];
  consignee: Department;
  customer: any;
  isLoadingAvailableGroups: boolean;
  isLoadingActiveGroup: boolean;
  dispatch: any;
  errorWhileCreating: any;
  errorWhileUpdating: any;
  errorWhileFetching: any;
}

function InvoicingRules(props: InvoicingRulesProps) {
  const {
    arAccessorialFees,
    accessorialRuleGroup,
    availableRuleGroups,
    consignee,
    customer,
    isLoadingAvailableGroups,
    isLoadingActiveGroup,
    dispatch,
    errorWhileCreating
  } = props;

  const consigneeId = consignee.uuid;
  function onRateTypeChange(accessorialRuleGroupUuid: string) {
    dispatch({ type: UPDATE_ACTIVE_REQUEST });
    const updatedRuleGroup = availableRuleGroups.find(
      (x: AccessorialRuleGroup) => x.attributes.uuid === accessorialRuleGroupUuid
    );

    updateActiveAccessorialRuleGroup(consigneeId, accessorialRuleGroupUuid).then(
      () => {
        raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Rate type was successfully updated" />);
        dispatch({ type: UPDATE_ACTIVE_SUCCESS, accessorialRuleGroup: updatedRuleGroup });
      },
      (error) => {
        raiseToast(
          <SystemToast
            type={SystemToast.Type.ERROR}
            message="Something went wrong while setting the selected rate type to be the active rate type"
          />
        );
        dispatch({ type: UPDATE_ACTIVE_ERROR, error });
        console.log({ e: error });
      }
    );
  }

  const accessorials = accessorialRuleGroup?.accessorials || [];

  const executeAppUIAction = useContext(appUIActionContext);

  const modalProps = {
    arAccessorialFees,
    accessorialRuleGroup,
    consignee,
    customer,
    dispatch,
    errorWhileCreating
  } as InvoicingRulesModalProps;

  function openModal(): void {
    executeAppUIAction(toggleAppModal(true, <InvoicingRulesModal {...modalProps} />));
  }

  const hasActiveRuleGroup = !!accessorialRuleGroup;
  const level = accessorialRuleGroup?.attributes?.CNEEUuid ? 'Consignee' : 'Global';
  const ruleGroupName = accessorialRuleGroup?.attributes?.name;

  const isBaseRate = !hasActiveRuleGroup;
  const standardOption = { name: 'Standard', value: 'standard', disabled: isBaseRate, icon: '🌐' } as Option;

  const optionsForDropdown = [standardOption];

  // Create a dropdown option for each element in availableRuleGroups
  availableRuleGroups.forEach((ruleGroup) => {
    const { attributes } = ruleGroup;
    const { name, uuid, CNEEUuid } = attributes;
    const isCustom = !!CNEEUuid;
    // TODO: Find better icons
    const icon = isCustom ? '' : '🌐';
    optionsForDropdown.push({ name, value: uuid, disabled: false, icon } as Option);
  });

  const sortedOptionsForDropdown = optionsForDropdown.sort((a) => {
    const isCustom = a.icon === '';
    // All the global rule groups will have the 🌐 icon.
    // Since there will only ever be one available custom rule group,
    // we can use the element with an `icon` value of `''` to determine which one is custom.
    if (isCustom) return -1; // first in list
    return 0;
  });

  const onChange = (values: Option[]) => {
    const { value } = values[0];

    if (value === 'standard') {
      if (!accessorialRuleGroup) return;

      const { attributes } = accessorialRuleGroup;
      const { uuid, CNEEUuid } = attributes;
      const isCustom = !!CNEEUuid;
      const activeGroupUuid = uuid;
      dispatch({ type: DELETE_ACTIVE_REQUEST });
      if (isCustom) {
        deleteCustomRuleGroup(activeGroupUuid).then(
          // deleteCustomRuleGroup and removeConsigneeFromGlobalRuleGroup both currently have the same callbacks.
          // Perfect world: DRY the code up
          () => {
            raiseToast(
              <SystemToast
                type={SystemToast.Type.SUCCESS}
                message="The CNEE was successfully updated to use the Standard rate type"
              />
            );
            dispatch({ type: DELETE_ACTIVE_SUCCESS });
          },
          () => {
            raiseToast(
              <SystemToast
                type={SystemToast.Type.ERROR}
                message="Something went wrong while setting the CNEE to the Standard Rate Type"
              />
            );
            dispatch({ type: DELETE_ACTIVE_ERROR });
          }
        );
      } else {
        // Perfect world: DRY the code up
        removeConsigneeFromGlobalRuleGroup(consigneeId, activeGroupUuid).then(
          () => {
            raiseToast(
              <SystemToast
                type={SystemToast.Type.SUCCESS}
                message="The CNEE was successfully updated to use the Standard rate type"
              />
            );
            dispatch({ type: DELETE_ACTIVE_SUCCESS });
          },
          () => {
            raiseToast(
              <SystemToast
                type={SystemToast.Type.ERROR}
                message="Something went wrong while setting the CNEE to the Standard Rate Type"
              />
            );
            dispatch({ type: DELETE_ACTIVE_ERROR });
          }
        );
      }

      // return to make sure we don't call onRateTypeChange
      return;
    }

    // Only call onRateTypeChange when value is not 'standard'
    onRateTypeChange(value);
  };

  let activeRuleGroupComponent = (
    <>
      <div>{consignee.name} doesn&apos;t currently have any special invoicing rules.</div>
      <div className="height-8" />
      <div>Please select a rule group from the dropdown or click the plus icon to create one.</div>
    </>
  );

  if (hasActiveRuleGroup) {
    activeRuleGroupComponent = (
      <>
        <div className="attributeLabel">Name</div>
        <div className="height-8" />
        <div>{ruleGroupName}</div>
        <div className="height-24" />
        <div className="attributeLabel">Level</div>
        <div className="height-8" />
        <div>{level}</div>
        <div className="height-24" />
        <div className="attributeLabel">Accessorials Included</div>
        <div className="height-8" />
        <div className="gridContainer">
          {accessorials.length === 0 ? <div>This rate type currently has no included accessorials.</div> : null}
          {accessorials.map((accessorial) => {
            const arAccessorialFee = arAccessorialFees.find((x) => x.attributes.feeType === accessorial.feeType);

            if (!arAccessorialFee) return null;
            return <div key={accessorial.uuid}>{arAccessorialFee?.attributes?.name}</div>;
          })}
        </div>
        <div className="height-24" />
        <div className="attributeLabel">Additional Fees</div>
        <div className="height-8" />
        <div>${accessorialRuleGroup?.attributes?.additionalFees}</div>
        <div className="height-24" />
        <div className="attributeLabel">Invoicing Rule Created</div>
        <div className="height-8" />
        <div>
          {accessorialRuleGroup?.attributes?.createdAt
            ? moment(accessorialRuleGroup?.attributes?.createdAt).format('MM/DD/YYYY HH:mm')
            : 'N/A'}
        </div>
      </>
    );
  }

  return (
    <div className="InvoicingRules">
      <div className="invoicingRulesHeading">Invoicing Rules</div>
      <div className="height-24" />
      <div className="attributeLabel">Rate Type</div>
      <div className="height-8" />

      <div className="flex justify-between">
        <div className="w-full">
          {isLoadingAvailableGroups && isLoadingActiveGroup ? (
            <span>Loading available rate types...</span>
          ) : (
            <DropdownSelectInput
              // eslint-disable-next-line react/no-unstable-nested-components
              itemRenderer={({ item, methods }: any) => (
                <button
                  type="button"
                  onClick={() => methods.addItem(item)}
                  className="cursor-pointer"
                  aria-label="button"
                >
                  <div className="flex height-32">
                    <span className="width-24 flex justify-center items-center">{item.icon}</span>
                    <span className="flex items-center">{item.name}</span>
                  </div>
                </button>
              )}
              options={sortedOptionsForDropdown}
              valueField="value"
              labelField="name"
              onChange={onChange}
              displayFormatter={() => accessorialRuleGroup?.attributes.name}
            />
          )}
        </div>

        <div>
          <button type="button" className="plusCircleIconButton" onClick={openModal} aria-label="Create rule button">
            <div className="plusCircleIconContainer">
              <PlusCircleIcon className="plusCircleIcon" />
            </div>
          </button>
        </div>
      </div>
      <div className="height-24" />
      {isLoadingActiveGroup ? <div>Loading active rate type...</div> : activeRuleGroupComponent}
    </div>
  );
}

export default InvoicingRules;
