import React, { useState } from 'react';
import Switch from 'react-switch';
import moment from 'moment-timezone';
import AngleDown from '@components/deprecatedTookit/icons/fa/regular/AngleDownIcon';
import AngleUp from '@components/deprecatedTookit/icons/fa/regular/AngleUpIcon';
import Checkbox from '@client/components/Checkbox/component';
import { TIME_ZONES } from '@drayalliance/types';
import DayWindow from './DayWindow/component';

import './style.css';

interface ImportExportDataProps {
  [key: string]: any;
  apptNeeded: boolean;
  apptOutreachEnabled: boolean;
  openTwentyFourHours: boolean;
  sameWeekdayHours: boolean;
  sundayHourOpen: string | null;
  sundayHourClose: string | null;
  mondayHourClose: string | null;
  mondayHourOpen: string | null;
  tuesdayHourOpen: string | null;
  tuesdayHourClose: string | null;
  wednesdayHourOpen: string | null;
  wendnesdayHourClose: string | null;
  thursdayHourOpen: string | null;
  thursdayHourClose: string | null;
  fridayHourOpen: string | null;
  fridayHourClose: string | null;
  saturdayHourOpen: string | null;
  saturdayHourClose: string | null;
}

interface ReceivingHoursDataProps {
  import: ImportExportDataProps;
  export: ImportExportDataProps;
}

interface ReceivingHoursProps {
  data: ReceivingHoursDataProps;
  editable: boolean;
  hidePreview: boolean;
  imExLabel: string;
  showTimezones: boolean;
  timezone: TIME_ZONES;
  // eslint-disable-next-line no-unused-vars
  onChange: (...arg: any) => any;
}

function ReceivingHours(props: ReceivingHoursProps) {
  const className = 'department-detail-sidebar__receiving-hours';
  const daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const [isOpen, setIsOpen] = useState(true);
  const [importApptNeeded, setImportApptNeeded] = useState(props.data.import && props.data.import.apptNeeded);
  const [importApptOutreachEnabled, setImportApptOutreachEnabled] = useState(
    props.data?.import?.apptNeeded && props.data?.import?.apptOutreachEnabled
  );
  const [importSameWeekdayHours, setImportSameWeekdayHours] = useState(props.data?.import?.sameWeekdayHours);
  const [exportSameWeekdayHours, setExportSameWeekdayHours] = useState(props.data?.export?.sameWeekdayHours);
  const [importFacility247, setImportFacility247] = useState(
    props.data?.import && props.data?.import?.openTwentyFourHours
  );

  const onApptNeededChange = (value: any) => {
    setImportApptNeeded(value);
    const newState = { apptNeeded: value };
    props.onChange(newState);
  };

  const onApptOutreachEnabledChange = (value: any) => {
    setImportApptOutreachEnabled(value);
    const newState = { apptOutreachEnabled: value };
    props.onChange(newState);
  };

  const onSameWeekdayHoursCheckboxChange = (value: any, importExport: 'import' | 'export') => {
    const resetState = {
      ...props.data[importExport],
      apptNeeded: false,
      fridayHourClose: null,
      fridayHourOpen: null,
      mondayHourClose: null,
      mondayHourOpen: null,
      openTwentyFourHours: false,
      saturdayHourClose: null,
      saturdayHourOpen: null,
      sundayHourClose: null,
      sundayHourOpen: null,
      thursdayHourClose: null,
      thursdayHourOpen: null,
      tuesdayHourClose: null,
      tuesdayHourOpen: null,
      wednesdayHourClose: null,
      wednesdayHourOpen: null,
      sameWeekdayHours: false
    };

    if (importExport === 'import') {
      setImportSameWeekdayHours(value);
    } else if (importExport === 'export') {
      setExportSameWeekdayHours(value);
    }
    const newState = value ? { sameWeekdayHours: value } : resetState;
    props.onChange(newState, importExport);
  };

  const onSameWeekdayHoursChange = (newData: { open: string; close: string }, importExport: 'import' | 'export') => {
    const { open, close } = newData;
    const newState = {
      ...props.data[importExport],
      apptNeeded: false,
      fridayHourClose: close,
      fridayHourOpen: open,
      mondayHourClose: close,
      mondayHourOpen: open,
      openTwentyFourHours: false,
      thursdayHourClose: close,
      thursdayHourOpen: open,
      tuesdayHourClose: close,
      tuesdayHourOpen: open,
      wednesdayHourClose: close,
      wednesdayHourOpen: open,
      sameWeekdayHours: true
    };
    props.onChange(newState, importExport);
  };

  const on247Change = (value: boolean, importExport: 'import' | 'export') => {
    setImportFacility247(value);

    const newState = {
      ...props.data[importExport],
      apptNeeded: false,
      fridayHourClose: null,
      fridayHourOpen: null,
      mondayHourClose: null,
      mondayHourOpen: null,
      openTwentyFourHours: false,
      saturdayHourClose: null,
      saturdayHourOpen: null,
      sundayHourClose: null,
      sundayHourOpen: null,
      thursdayHourClose: null,
      thursdayHourOpen: null,
      tuesdayHourClose: null,
      tuesdayHourOpen: null,
      wednesdayHourClose: null,
      wednesdayHourOpen: null,
      sameWeekdayHours: false
    };

    newState.openTwentyFourHours = value;

    props.onChange(newState);
  };

  const importDayWindows: any = [];
  const exportDayWindows: any = [];

  if (!importFacility247) {
    daysOfTheWeek.forEach((day: string) => {
      const skippedWeekdays = ['tuesday', 'wednesday', 'thursday', 'friday']; // left monday out to push Monday-Friday Item
      const closeKey = `${day}HourClose`;
      const openKey = `${day}HourOpen`;
      const importData = {
        open: props.data.import[openKey],
        close: props.data.import[closeKey]
      };
      const exportData = {
        open: props.data.export[openKey],
        close: props.data.export[closeKey]
      };

      const onDayWindowChange = (newData: { open: string; close: string }, importExport: 'import' | 'export') => {
        const newState = { ...props.data[importExport], [closeKey]: newData.close, [openKey]: newData.open };
        props.onChange(newState, importExport);
      };

      const useSameWeekdayHours = !skippedWeekdays.includes(day);
      const useSameWeekdayHoursPrefix = useSameWeekdayHours && day === 'monday';
      const allWeekdaysPrefix = 'Monday - Friday';

      if (importSameWeekdayHours && useSameWeekdayHours) {
        importDayWindows.push(
          <DayWindow
            data={importData}
            dayPrefix={useSameWeekdayHoursPrefix ? allWeekdaysPrefix : day}
            key={useSameWeekdayHoursPrefix ? allWeekdaysPrefix : day}
            onChange={
              importSameWeekdayHours
                ? (newData: any) => onSameWeekdayHoursChange(newData, 'import')
                : (newData: any) => onDayWindowChange(newData, 'import')
            }
            editable={props.editable}
            isSameWeekdayHours={useSameWeekdayHoursPrefix}
            showTimezones={props.showTimezones}
            timezone={props.timezone}
          />
        );
      } else if (!importSameWeekdayHours) {
        importDayWindows.push(
          <DayWindow
            data={importData}
            dayPrefix={day}
            key={day}
            onChange={(newData: any) => onDayWindowChange(newData, 'import')}
            editable={props.editable}
            showTimezones={props.showTimezones}
            timezone={props.timezone}
          />
        );
      }

      if (exportSameWeekdayHours && useSameWeekdayHours) {
        exportDayWindows.push(
          <DayWindow
            data={exportData}
            dayPrefix={useSameWeekdayHoursPrefix ? allWeekdaysPrefix : day}
            key={useSameWeekdayHoursPrefix ? allWeekdaysPrefix : day}
            onChange={
              exportSameWeekdayHours
                ? (newData: any) => onSameWeekdayHoursChange(newData, 'export')
                : (newData: any) => onDayWindowChange(newData, 'export')
            }
            editable={props.editable}
            isSameWeekdayHours={useSameWeekdayHoursPrefix}
            showTimezones={props.showTimezones}
            timezone={props.timezone}
          />
        );
      } else if (!exportSameWeekdayHours) {
        exportDayWindows.push(
          <DayWindow
            data={exportData}
            dayPrefix={day}
            key={day}
            onChange={(newData: any) => onDayWindowChange(newData, 'export')}
            editable={props.editable}
            showTimezones={props.showTimezones}
            timezone={props.timezone}
          />
        );
      }
    });
  }

  return (
    <>
      {!props.hidePreview && (
        <div className={`${className}-preview`} onClick={() => setIsOpen(!isOpen)}>
          <h3>{props.imExLabel} Delivery Preference</h3>
          {isOpen ? (
            <AngleUp className={`${className}-open-icon`} />
          ) : (
            <AngleDown className={`${className}-open-icon`} />
          )}
        </div>
      )}
      <div className={`${className}-switch`}>
        <Switch
          disabled={!props.editable}
          checked={!!importApptNeeded}
          onChange={onApptNeededChange}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#24b248"
          offColor="#c6cbd4"
          width={32}
          height={16}
          className={`${className}-receiving-hours-sidebar-header-switch`}
        />
        <span className={`${className}-receiving-hours-sidebar-title`}>Appt Needed</span>
      </div>
      <div className={`${className}-switch`}>
        <Switch
          disabled={!props.editable || !importApptNeeded}
          checked={!!importApptOutreachEnabled}
          onChange={onApptOutreachEnabledChange}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#24b248"
          offColor="#c6cbd4"
          width={32}
          height={16}
          className={`${className}-receiving-hours-sidebar-header-switch`}
        />
        <span className={`${className}-receiving-hours-sidebar-title`}>Appt Outreach Enabled</span>
      </div>
      {isOpen && (
        <div className={`${className}-wrapper`}>
          <p className={`${className}-section-sub-title`}>
            Preferred Operating Hours
            {props.editable && props.showTimezones && !importFacility247
              ? ` (Times are in ${moment().tz(props.timezone).format('z')})`
              : ''}
          </p>
          <div className={`${className}-header`}>
            <span className={`${className}-switch`}>
              <Switch
                disabled={!props.editable}
                checked={!!importFacility247}
                onChange={(newData) => on247Change(newData, 'import')}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#24b248"
                offColor="#c6cbd4"
                width={32}
                height={16}
                className={`${className}-receiving-hours-sidebar-header-switch`}
              />
              <span className={`${className}-receiving-hours-sidebar-title`}>24/7 Facility</span>
            </span>
          </div>
          {!importFacility247 && (
            <div className={`${className}-content`}>
              <div className={`${className}-day-windows`}>
                <div className={`${className}-day-windows-header`}>
                  <span className={`${className}-section-sub-title`}>Import</span>
                </div>
                {props.editable && (
                  <span className={`${className}-checkbox`}>
                    <Checkbox
                      checked={importSameWeekdayHours}
                      onToggle={(newData) => onSameWeekdayHoursCheckboxChange(newData, 'import')}
                    />
                    <span className={`${className}-section-sub-title`}>Same Weekday Hours</span>
                  </span>
                )}
                {importDayWindows}
              </div>
              <div className={`${className}-day-windows`}>
                <div className={`${className}-day-windows-header`}>
                  <span className={`${className}-section-sub-title`}>Export</span>
                </div>
                {props.editable && (
                  <span className={`${className}-checkbox`}>
                    <div>
                      <Checkbox
                        checked={exportSameWeekdayHours}
                        onToggle={(newData: {}) => onSameWeekdayHoursCheckboxChange(newData, 'export')}
                      />
                      <span className={`${className}-section-sub-title`}>Same Weekday Hours</span>
                    </div>
                  </span>
                )}
                {exportDayWindows}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ReceivingHours;
