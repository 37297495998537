import request from '@client/utils/request';
import { api } from '@client/utils/url';

export function updateActiveAccessorialRuleGroup(cneeUUID: string, ruleGroupUuid: string) {
  const url = api('/accessorial-rule-groups/departments');
  const payload = { [cneeUUID]: ruleGroupUuid };
  const body = JSON.stringify(payload);
  const headers = { 'Content-Type': 'application/json' };
  const opts = { method: 'POST', body, headers };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}
