import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import LoadingSpinner from '@components/deprecatedTookit/LoadingSpinner';
import Switch from 'react-switch';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { selectDepartmentsById } from '@client/_blessed/store/entities/departments';
import ReceivingHours from './components/ReceivingHours/index';
import {
  retrieveDeliveryPreferences,
  updateDeliveryPreferences
} from '../../../../state/resources/consigneeDeliveryPreference/actions';
import { appActionContext } from '../../../App/contexts';

interface DeliveryPreferencesSidebarProps {
  departmentId: string;
  hidePreview: boolean;
  editable: boolean;
}

function DeliveryPreferencesSidebar(props: DeliveryPreferencesSidebarProps) {
  const className = 'department-sidebar-delivery-preferences';
  const [sameImExConfirmation, setSameImExConfirmation] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [sameRecievingHours, setSameReceivingHours] = useState(false);
  const [, setCopyFrom] = useState('import');
  const appDispatch = useContext(appActionContext);
  const [deliveryPreferences, setDeliveryPreferences] = useState<Record<string, any>>({
    apptNeeded: false,
    openTwentyFourHours: false,
    sameRecievingHours: true,
    importId: null,
    exportId: null,
    import: {},
    export: {}
  });
  const { showTimezones } = useFlags();
  const departmentsById = useSelector(selectDepartmentsById);

  const department = departmentsById?.[props.departmentId];

  const formatDeliveryPreferences = (response: any) => {
    let importData: Record<string, any> = {};
    let exportData: Record<string, any> = {};

    response.data.forEach((item: any) => {
      if (item.attributes.type === 'import') importData = item;
      if (item.attributes.type === 'export') exportData = item;
    });

    const newData = {
      ...deliveryPreferences,
      import: {
        apptNeeded: importData.attributes.apptNeeded,
        apptOutreachEnabled: importData.attributes.apptOutreachEnabled,
        openTwentyFourHours: importData.attributes.openTwentyFourHours,
        mondayHourClose: importData.attributes.mondayHourClose,
        mondayHourOpen: importData.attributes.mondayHourOpen,
        tuesdayHourClose: importData.attributes.tuesdayHourClose,
        tuesdayHourOpen: importData.attributes.tuesdayHourOpen,
        wednesdayHourClose: importData.attributes.wednesdayHourClose,
        wednesdayHourOpen: importData.attributes.wednesdayHourOpen,
        thursdayHourClose: importData.attributes.thursdayHourClose,
        thursdayHourOpen: importData.attributes.thursdayHourOpen,
        fridayHourClose: importData.attributes.fridayHourClose,
        fridayHourOpen: importData.attributes.fridayHourOpen,
        saturdayHourClose: importData.attributes.saturdayHourClose,
        saturdayHourOpen: importData.attributes.saturdayHourOpen,
        sundayHourClose: importData.attributes.sundayHourClose,
        sundayHourOpen: importData.attributes.sundayHourOpen,
        sameWeekdayHours: importData.attributes.sameWeekdayHours
      },
      export: {
        apptNeeded: exportData.attributes.apptNeeded,
        apptOutreachEnabled: exportData.attributes.apptOutreachEnabled,
        openTwentyFourHours: exportData.attributes.openTwentyFourHours,
        mondayHourClose: exportData.attributes.mondayHourClose,
        mondayHourOpen: exportData.attributes.mondayHourOpen,
        tuesdayHourClose: exportData.attributes.tuesdayHourClose,
        tuesdayHourOpen: exportData.attributes.tuesdayHourOpen,
        wednesdayHourClose: exportData.attributes.wednesdayHourClose,
        wednesdayHourOpen: exportData.attributes.wednesdayHourOpen,
        thursdayHourClose: exportData.attributes.thursdayHourClose,
        thursdayHourOpen: exportData.attributes.thursdayHourOpen,
        fridayHourClose: exportData.attributes.fridayHourClose,
        fridayHourOpen: exportData.attributes.fridayHourOpen,
        saturdayHourClose: exportData.attributes.saturdayHourClose,
        saturdayHourOpen: exportData.attributes.saturdayHourOpen,
        sundayHourClose: exportData.attributes.sundayHourClose,
        sundayHourOpen: exportData.attributes.sundayHourOpen,
        sameWeekdayHours: exportData.attributes.sameWeekdayHours
      },
      importId: importData.id,
      exportId: exportData.id
    };

    return newData;
  };

  const getDeliveryPreferences = async () => {
    setIsFetching(true);
    try {
      const response = await appDispatch(retrieveDeliveryPreferences(props.departmentId));
      const newData = formatDeliveryPreferences(response);
      if (_.isEqual(newData.import, newData.export)) setSameReceivingHours(true);
      setDeliveryPreferences(newData);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
    }
  };

  const onChange = async (newData: any) => {
    try {
      const data = [
        {
          type: 'consigneeDeliveryPreference',
          id: newData.importId,
          attributes: { ...newData.import }
        },
        {
          type: 'consigneeDeliveryPreference',
          id: newData.exportId,
          attributes: { ...newData.export }
        }
      ];

      setIsFetching(true);
      await appDispatch(updateDeliveryPreferences(data));
      getDeliveryPreferences();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDeliveryPreferences();
  }, []);

  const setReceivingHours = (newData: any, imEx: any) => {
    if (imEx) {
      onChange({ ...deliveryPreferences, [imEx]: newData });
    } else {
      onChange({ ...deliveryPreferences, import: newData, export: newData });
    }
  };

  const receivingHoursSliderHandler = (value: any, imEx?: 'import' | 'export') => {
    if (value) {
      if (!imEx) {
        setSameImExConfirmation(true);
      } else {
        const imExOpposite = imEx === 'import' ? 'export' : 'import';
        onChange({
          ...deliveryPreferences,
          [imExOpposite]: deliveryPreferences[imEx]
        });
        setCopyFrom(imEx);
        setSameReceivingHours(value);
        setSameImExConfirmation(false);
      }
    } else {
      onChange({ ...deliveryPreferences, sameRecievingHours: value });
      setSameReceivingHours(value);
    }
  };

  const dataExists = deliveryPreferences.importId || deliveryPreferences.exportId;

  return (
    <div className={`${className}-wrapper`}>
      {isFetching ? (
        <LoadingSpinner className={`${className}-spinner`} />
      ) : (
        dataExists && (
          <>
            <div className={`${className}-receiving-hours-sidebar-header`}>
              {props.editable && (
                <div className={`${className}-receiving-hours-sidebar-header-switch-wrapper`}>
                  <Switch
                    checked={sameRecievingHours}
                    onChange={(value: any) => receivingHoursSliderHandler(value)}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#24b248"
                    offColor="#c6cbd4"
                    width={32}
                    height={16}
                    className={`${className}-receiving-hours-sidebar-header-switch`}
                  />
                  <h3 className={`${className}-receiving-hours-sidebar-title`}>Same Import/Export Hours</h3>
                </div>
              )}
              {sameImExConfirmation && ( // Can be reached when consigneePreferencesUxUpdate = FALSE
                <>
                  <div className={`${className}-same-imex-comfirmation`}>
                    <p className={`${className}-receiving-hours-sidebar-title`}>Are you sure?</p>
                  </div>
                  <div className={`${className}-copy-options-wrapper`}>
                    <div
                      className={`${className}-same-imex-comfirmation-button`}
                      onClick={() => receivingHoursSliderHandler(true, 'import')}
                    >
                      Yes, copy from Import
                    </div>
                    <div
                      className={`${className}-same-imex-comfirmation-button`}
                      onClick={() => receivingHoursSliderHandler(true, 'export')}
                    >
                      Yes, copy from Export
                    </div>
                    <div
                      className={`${className}-same-imex-comfirmation-button`}
                      onClick={() => {
                        setSameImExConfirmation(false);
                        setSameReceivingHours(false);
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={`${className}-receiving-hours-section`}>
              <ReceivingHours
                imExLabel="Import"
                hidePreview={props.hidePreview}
                data={{ import: deliveryPreferences.import, export: deliveryPreferences.export }}
                onChange={(newState, importExport) => {
                  setReceivingHours(newState, importExport);
                }}
                editable={props.editable}
                showTimezones={showTimezones}
                timezone={department.timezone}
              />
            </div>
          </>
        )
      )}
    </div>
  );
}

export default DeliveryPreferencesSidebar;
