// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-detail-sidebar-sop {
  width: 100%;
  height: 100%;
}

  .department-detail-sidebar-sop__section {
    margin-bottom: 30px;
  }

  .department-detail-sidebar-sop__section-title {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .department-detail-sidebar-sop__section-subtitle {
    color: #737980;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
  }

  .department-detail-sidebar-sop__contact-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .department-detail-sidebar-sop__contact-details {
    display: flex;
    align-items: center;
  }

  .department-detail-sidebar-sop__contact {
    margin-right: 20px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarSOP/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AAiCd;;EA/BE;IACE,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".department-detail-sidebar-sop {\n  width: 100%;\n  height: 100%;\n\n  &__section {\n    margin-bottom: 30px;\n  }\n\n  &__section-title {\n    margin-bottom: 10px;\n    font-weight: 600;\n  }\n\n  &__section-subtitle {\n    color: #737980;\n    font-size: 12px;\n    font-weight: 500;\n    margin-bottom: 0;\n    width: 100%;\n  }\n\n  &__contact-container {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 16px;\n  }\n\n  &__contact-details {\n    display: flex;\n    align-items: center;\n  }\n\n  &__contact {\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
