// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-detail-sidebar-trucks {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 760px;
}

  .department-detail-sidebar-trucks__header {
    padding-bottom: 25px;
  }

  .department-detail-sidebar-trucks__header-plus-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

  .department-detail-sidebar-trucks__body {
    flex-grow: 1;
  }

  .department-detail-sidebar-trucks-times-icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    color: red;
    cursor: pointer;
  }

  .department-detail-sidebar-trucks-trash-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .department-detail-sidebar-trucks-trash-icon:hover {
      color: var(--color-green-8);
    }
`, "",{"version":3,"sources":["webpack://./app/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarTrucks/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;AAiCd;;EA/BE;IACE,oBAAoB;EAOtB;;EALE;MACE,WAAW;MACX,YAAY;MACZ,eAAe;IACjB;;EAGF;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EAKjB;;EAHE;MACE,2BAA2B;IAC7B","sourcesContent":[".department-detail-sidebar-trucks {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 760px;\n\n  &__header {\n    padding-bottom: 25px;\n\n    &-plus-icon {\n      width: 20px;\n      height: 20px;\n      cursor: pointer;\n    }\n  }\n\n  &__body {\n    flex-grow: 1;\n  }\n\n  &-times-icon {\n    margin-left: 10px;\n    width: 20px;\n    height: 20px;\n    color: red;\n    cursor: pointer;\n  }\n\n  &-trash-icon {\n    width: 15px;\n    height: 15px;\n    cursor: pointer;\n\n    &:hover {\n      color: var(--color-green-8);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
