import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import InfoIcon from '@components/deprecatedTookit/icons/fa/light/InfoCircleIcon';
import UsersIcon from '@components/deprecatedTookit/icons/fa/light/UserFriendsIcon';
import TruckIcon from '@components/deprecatedTookit/icons/fa/light/TruckContainerIcon';
import CalculatorIcon from '@components/deprecatedTookit/icons/fa/light/CalculatorIcon'; // TODO: Find an icon that better matches the mock
import FileContractIcon from '@components/deprecatedTookit/icons/fa/light/FileContractIcon';
import DollarSignIcon from '@components/deprecatedTookit/icons/fa/light/DollarSignIcon';
import LoadingSpinner from '@components/deprecatedTookit/LoadingSpinner';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import ClipboardCheckIcon from '@components/deprecatedTookit/icons/fa/light/ClipboardCheckIcon';
import { useSelector } from 'react-redux';
import { selectDepartmentsById, selectDepartmentsLoading } from '@client/_blessed/store/entities/departments';
import {
  fetchUsersForDepartment,
  selectUsersByDepartmentUuid,
  selectUsersLoadingByDepartmentUuid
} from '@client/_blessed/store/entities/users';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import ToggleGroup from '../ToggleGroup';
import DepartmentDetailSidebarInfo from './components/DepartmentDetailSidebarInfo';
import DepartmentDetailSidebarBanking from './components/DepartmentDetailSidebarBanking';
import DepartmentDetailSidebarTrucks from './components/DepartmentDetailSidebarTrucks';
import DepartmentDetailSidebarDocuments from './components/DepartmentDetailSidebarDocuments';
import DepartmentDetailSidebarPeople from './components/DepartmentDetailSidebarPeople';
import DepartmentDetailSidebarDeliveryPreferences from './components/DepartmentDetailSidebarDeliveryPreferences';
import InvoicingRules from './components/DepartmentDetailSidebarInvoicingRules';
import { appStateContext, appActionContext, appUIActionContext } from '../App/contexts';
import { canIUse } from '../../utils/can-i-use';
import { getDepartmentDisplayName } from '../../utils/data-processing/department';
import DepartmentDropdown from './components/DepartmentDropdown/component';
import SidebarHeader from '../SidebarHeader';
import SidebarNavigation from '../SidebarNavigation';
import SidebarNavLink from '../SidebarNavLink';
import { closeRightSidebar } from '../../state/ui/actions';
import { deleteDepartment } from '../../state/resources/departments/actions';
import { retrieveRoles, retrieveRolesByDepartmentType } from '../../state/resources/roles/actions';
import { retrieveTrucksByQuery } from '../../state/resources/trucks/actions';
import DepartmentDetailSidebarSOP from './components/DepartmentDetailSidebarSOP';

import './style.css';

interface DepartmentDetailSidebarProps {
  departmentId: string;
  selectedTab?: string;
  title: string;
  stopInstant?: Record<string, any>;
  optimisticallyRemoveDepartment: (...args: any) => any;
  canDelete: boolean;
  onOrderUpdated?: (...args: any) => any;
  stop?: Record<string, any>;
  editable: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  legId?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  leg?: Record<string, any>;
  orderId?: string;
}

interface DepartmentDetailSidebarState {
  selectedTab: string;
  truckCount: number | null;
  departmentTrucks: Record<string, any>[];
  roles: Record<string, any>[];
}

function DepartmentDetailSidebar(props: DepartmentDetailSidebarProps) {
  const flagSet = useFlags();
  const { allInRate } = flagSet;

  const dispatch = useAppDispatch();
  const appState = useContext(appStateContext);
  const appDispatch = useContext(appActionContext);
  const appUiDispatch = useContext(appUIActionContext);
  const [navOpen, setNavOpen] = useState(false);
  const [state, setState] = useState<DepartmentDetailSidebarState>({
    selectedTab: props.selectedTab || 'info',
    truckCount: null,
    departmentTrucks: [],
    roles: []
  });

  const className = 'department-detail-sidebar';
  let sectionComponent = null;

  const departmentsLoading = useSelector(selectDepartmentsLoading);
  const departmentsById = useSelector(selectDepartmentsById);

  const usersLoading = useSelector(selectUsersLoadingByDepartmentUuid);
  const usersByDepartmentUuid = useSelector(selectUsersByDepartmentUuid);

  const department = departmentsById[props.departmentId];
  const departmentUsers = usersByDepartmentUuid[props.departmentId];

  useEffect(() => {
    // Ensure that app state is populated with the department.
    let promise: ReturnType<ReturnType<typeof fetchUsersForDepartment>> | null = null;
    if (!usersLoading && usersByDepartmentUuid[props.departmentId] === undefined) {
      promise = dispatch(fetchUsersForDepartment(props.departmentId));
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [props.departmentId, usersLoading, usersByDepartmentUuid]);

  const loadUsers = async () => {
    await dispatch(fetchUsersForDepartment(department.uuid));
    await appDispatch(retrieveRoles());
    const rolesResponse = await appDispatch(retrieveRolesByDepartmentType(department.type));

    const flattenedRoles = rolesResponse.data.map((role: any) => {
      const flattened = { ...role, ...role.attributes };
      return flattened;
    });

    setState((prevState) => ({
      ...prevState,
      roles: flattenedRoles
    }));
  };

  const loadTrucks = async () => {
    const response = await appDispatch(retrieveTrucksByQuery({ departmentUuid: props.departmentId }));

    let truckCount = 0;
    if (response.data.length) {
      truckCount = response.data.length;
    }
    const departmentTrucks = response.data;

    setState((prevState) => ({
      ...prevState,
      truckCount,
      departmentTrucks
    }));
  };

  useEffect(() => {
    loadUsers();
    loadTrucks();

    return () => {
      setState({
        ...state,
        truckCount: null,
        departmentTrucks: [],
        roles: []
      });
    };
  }, [props.departmentId]);

  if (departmentsLoading || usersLoading || !department || !departmentUsers) {
    return <LoadingScreen />;
  }

  const departmentType = department.type;
  const isCarrier = departmentType === 'carrier';

  const isYard = departmentType === 'yard';
  const isConsignee = departmentType === 'consignee';
  const isTerminal = departmentType === 'terminal';
  const isRailYard = departmentType === 'rail_yard';
  const showSOP = isYard || isConsignee || isTerminal || isRailYard;

  const handleTabChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      selectedTab: event.values[0]
    }));
    setNavOpen(false);
  };

  const handleDelete = async () => {
    try {
      await appDispatch(deleteDepartment(props.departmentId));
      props.optimisticallyRemoveDepartment(props.departmentId);
      await appUiDispatch(closeRightSidebar());
    } catch (error) {
      console.error(error);
    }
  };

  switch (state.selectedTab) {
    case 'info':
      sectionComponent = (
        <DepartmentDetailSidebarInfo
          departmentId={props.departmentId}
          stopInstant={props.stopInstant}
          optimisticallyRemoveDepartment={props.optimisticallyRemoveDepartment}
          onOrderUpdated={props.onOrderUpdated}
          editable={props.editable}
        />
      );
      break;
    case 'sop':
      sectionComponent = (
        <DepartmentDetailSidebarSOP
          title={props.title}
          department={department}
          departmentType={departmentType}
          departmentId={props.departmentId}
          stopInstant={props.stopInstant}
          stop={props.stop}
          orderId={props.orderId}
        />
      );
      break;
    case 'trucks':
      sectionComponent = (
        <DepartmentDetailSidebarTrucks
          departmentId={props.departmentId}
          trucks={state.departmentTrucks}
          setParentState={setState}
          onLoadTrucks={loadTrucks}
        />
      );
      break;
    case 'banking':
      sectionComponent = <DepartmentDetailSidebarBanking departmentId={props.departmentId} />;
      break;
    case 'documents':
      sectionComponent = <DepartmentDetailSidebarDocuments departmentId={props.departmentId} />;
      break;
    case 'delivery':
      sectionComponent = (
        <DepartmentDetailSidebarDeliveryPreferences
          departmentId={props.departmentId}
          hidePreview={false}
          editable={false}
        />
      );
      break;
    case 'invoicing-rules':
      sectionComponent = <InvoicingRules departmentId={props.departmentId} />;
      break;
    case 'people':
      sectionComponent = (
        <DepartmentDetailSidebarPeople
          departmentId={props.departmentId}
          users={departmentUsers}
          roles={state.roles}
          onLoadUsers={loadUsers}
        />
      );
      break;
    default:
  }

  const myDepartmentType = appState['auth.selectedDepartment'].type;
  const createdOnDate = moment(department.createdAt).format('MM/DD/YYYY');

  return (
    <div className={className}>
      <SidebarHeader
        title={props.title || 'Department'}
        subtitle={getDepartmentDisplayName(department)}
        createdOn={createdOnDate}
        right={
          props.canDelete &&
          canIUse({
            role: myDepartmentType,
            blacklistGroups: ['customer']
          }) && <DepartmentDropdown handleDelete={handleDelete} />
        }
      />
      <SidebarNavigation open={navOpen} onMouseEnter={() => setNavOpen(true)} onMouseLeave={() => setNavOpen(false)}>
        <ToggleGroup values={[state.selectedTab]} onChange={handleTabChange}>
          {({
            toggle,
            isOn
          }: {
            // eslint-disable-next-line react/no-unused-prop-types
            toggle: (value: string) => any;
            // eslint-disable-next-line react/no-unused-prop-types
            isOn: (value: string) => any;
          }) => {
            const shouldRenderInvoicingRulesLink = isConsignee && allInRate;
            return (
              <>
                <SidebarNavLink
                  className={`${className}__navigation-button ${
                    isOn('info') ? `${className}__navigation-button-selected` : ''
                  }`}
                  value="Info"
                  icon={<InfoIcon />}
                  onClick={() => !isOn('info') && toggle('info')}
                  disableDefaultSelectedStyle
                />
                {showSOP && (
                  <SidebarNavLink
                    className={`${className}__navigation-button ${
                      isOn('sop') ? `${className}__navigation-button-selected` : ''
                    }`}
                    value="SOP"
                    icon={<ClipboardCheckIcon />}
                    onClick={() => !isOn('sop') && toggle('sop')}
                    disableDefaultSelectedStyle
                  />
                )}
                {shouldRenderInvoicingRulesLink ? (
                  <SidebarNavLink
                    className={`${className}__navigation-button ${
                      isOn('invoicing-rules') ? `${className}__navigation-button-selected` : ''
                    }`}
                    value="Invoicing Rules"
                    icon={<CalculatorIcon />}
                    onClick={() => !isOn('invoicing-rules') && toggle('invoicing-rules')}
                    disableDefaultSelectedStyle
                  />
                ) : null}
                <SidebarNavLink
                  className={`${className}__navigation-button ${
                    isOn('people') ? `${className}__navigation-button-selected` : ''
                  }`}
                  value="People"
                  icon={
                    <div>
                      <UsersIcon />
                      <span className={`${className}__navigation-button-spinner-container`}>
                        {departmentUsers.length >= 0 ? (
                          <span className={`${className}__navigation-button-count`}>{departmentUsers.length}</span>
                        ) : (
                          <LoadingSpinner className={`${className}__navigation-button-spinner`} />
                        )}
                      </span>
                    </div>
                  }
                  onClick={() => !isOn('people') && toggle('people')}
                  disableDefaultSelectedStyle
                />
                {isCarrier && (
                  <SidebarNavLink
                    className={`${className}__navigation-button ${
                      isOn('trucks') ? `${className}__navigation-button-selected` : ''
                    }`}
                    value="Trucks"
                    icon={
                      <div>
                        <TruckIcon />
                        <span className={`${className}__navigation-button-spinner-container`}>
                          {state.truckCount ? (
                            <span className={`${className}__navigation-button-count`}>{state.truckCount}</span>
                          ) : (
                            <LoadingSpinner className={`${className}__navigation-button-spinner`} />
                          )}
                        </span>
                      </div>
                    }
                    onClick={() => !isOn('trucks') && toggle('trucks')}
                    disableDefaultSelectedStyle
                  />
                )}
                {isCarrier && (
                  <SidebarNavLink
                    className={`${className}__navigation-button ${
                      isOn('documents') ? `${className}__navigation-button-selected` : ''
                    }`}
                    value="Documents"
                    icon={<FileContractIcon />}
                    onClick={() => !isOn('documents') && toggle('documents')}
                    disableDefaultSelectedStyle
                  />
                )}
                {isCarrier && (
                  <SidebarNavLink
                    className={`${className}__navigation-button ${
                      isOn('banking') ? `${className}__navigation-button-selected` : ''
                    }`}
                    value="Banking"
                    icon={<DollarSignIcon />}
                    onClick={() => !isOn('banking') && toggle('banking')}
                    disableDefaultSelectedStyle
                  />
                )}
              </>
            );
          }}
        </ToggleGroup>
      </SidebarNavigation>
      <div className="department-detail-sidebar__content">{sectionComponent}</div>
    </div>
  );
}

export default DepartmentDetailSidebar;
