// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-detail-sidebar-banking {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 350px;
}

  .department-detail-sidebar-banking__label {
    opacity: 0.5;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 10px;
  }

  .department-detail-sidebar-banking__value {
    height: 24px;
  }

  .department-detail-sidebar-banking__save-button {
    margin-top: 24px;
  }
`, "",{"version":3,"sources":["webpack://./app/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarBanking/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;AAiBd;;EAfE;IACE,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".department-detail-sidebar-banking {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 350px;\n\n  &__label {\n    opacity: 0.5;\n    font-size: 12px;\n    line-height: 24px;\n    font-weight: 500;\n    margin-top: 10px;\n  }\n\n  &__value {\n    height: 24px;\n  }\n\n  &__save-button {\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
