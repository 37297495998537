import React, { useContext, useState, useEffect, useMemo } from 'react';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import { useSelector } from 'react-redux';
import { selectDepartmentsById } from '@client/_blessed/store/entities';
import { appActionContext, appUIActionContext } from '../../../App/contexts';
import { retrieveFile } from '../../../../state/resources/files/actions';
import { toggleAppModal } from '../../../../state/ui/actions';
import FileThumbnail from '../../../FileThumbnail';
import NewDocumentUploader from '../../../NewDocumentUploader';
import { onboardingAttachmentTypeUIDropdownOptions } from '../../../../constants';
import SidebarDocumentsArchive from '../../../SidebarDocumentsArchive';
import { useAppDispatch } from '../../../../_blessed/hooks/useAppDispatch';
import { Attachment } from '../../../../_blessed/components/features/Dispatch/store/attachments/model';
import {
  fetchAttachmentsByDepartmentUuid,
  selectLoadingAttachmentsByDepartmentUuid
} from '../../../../_blessed/components/features/Dispatch/store';

import './style.css';

interface DepartmentDetailSidebarDocumentsProps {
  departmentId: string;
}

interface DepartmentDetailSidebarDocumentsState {
  attachments: null | Attachment[];
  deletedAttachments: Attachment[];
  errors: null | any[];
}

function DepartmentDetailSidebarDocuments(props: DepartmentDetailSidebarDocumentsProps) {
  const appDispatch = useAppDispatch();
  const appActionDispatch = useContext(appActionContext);
  const appDispatchUI = useContext(appUIActionContext);
  const [state, setState] = useState<DepartmentDetailSidebarDocumentsState>({
    attachments: null,
    deletedAttachments: [],
    errors: null
  });

  const className = 'department-detail-sidebar-documents';
  const departmentsById = useSelector(selectDepartmentsById);
  const isLoadingAttachmentsForDepartment = useSelector(selectLoadingAttachmentsByDepartmentUuid);
  const department = departmentsById[props.departmentId];

  const documentUploaderDDOptions = useMemo(
    () => [{ label: '', value: null }, ...onboardingAttachmentTypeUIDropdownOptions],
    []
  );

  const loadData = async () => {
    setState((prevState) => ({
      ...prevState,
      errors: null,
      isRetrieving: true
    }));

    try {
      const attachmentsResponse = await appDispatch(fetchAttachmentsByDepartmentUuid(props.departmentId)).unwrap();
      const attachments = Array.from(attachmentsResponse || []);

      const [activeAttachments, deletedAttachments] = attachments.reduce(
        (carry, current) => {
          if (current.deletedAt) {
            return [carry[0], [...carry[1], current]];
          }
          return [[...carry[0], current], carry[1]];
        },
        [[], []] as [Attachment[], Attachment[]]
      );

      setState((prevState) => ({
        ...prevState,
        attachments: activeAttachments,
        deletedAttachments,
        isRetrieving: false
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        errors: (err as any).errors || [],
        isRetrieving: false
      }));
    }
  };

  const handleAttachmentCreated = () => {
    loadData();
  };

  useEffect(() => {
    loadData();

    return () => {
      setState(() => ({
        errors: null,
        isRetrieving: false,
        attachments: null,
        deletedAttachments: []
      }));
    };
  }, []);

  let innerComponent = null;

  if (!state.attachments && isLoadingAttachmentsForDepartment[props.departmentId]) {
    innerComponent = <LoadingScreen />;
  } else if (state.errors) {
    innerComponent = 'error';
  } else if (state.attachments) {
    innerComponent = (
      <div className="department-detail-sidebar-documents__thumbnails">
        {state.attachments.map((attachment) => {
          const { file } = attachment;

          if (!file) {
            return null;
          }

          let filename = `department-${department.uuid}-${attachment.type}-${attachment.uuid}`;

          if (file.type) {
            filename += `.${file.type}`;
          }

          return (
            <FileThumbnail
              key={attachment.uuid}
              displayName={attachment.type}
              filename={filename}
              filePath={file.url}
              fileType={file.type}
              timestampOne={attachment.updatedAt}
              attachmentId={attachment.uuid}
              attachmentOwnerId={attachment.ownerUUID}
              attachmentOwnerType={attachment.ownerType}
              attachmentType={attachment.type}
              onAttachmentCreated={handleAttachmentCreated}
              onGetImage={() => appActionDispatch(retrieveFile(file.type, file.uuid, attachment.name))}
              onImageClick={(img) => appDispatchUI(toggleAppModal(true, img))}
              onNameClick={(img) => appDispatchUI(toggleAppModal(true, img))}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="department-detail-sidebar-documents__new-doc-uploader-section">
        <NewDocumentUploader
          attachmentOwnerId={props.departmentId}
          attachmentOwnerType="department"
          dropdownOptions={documentUploaderDDOptions}
          onAttachmentCreated={handleAttachmentCreated}
        />
      </div>
      {innerComponent}
      <SidebarDocumentsArchive
        department={department}
        attachments={state.deletedAttachments}
        onNameClick={(img) => appDispatchUI(toggleAppModal(true, img))}
      />
    </div>
  );
}

export default DepartmentDetailSidebarDocuments;
