import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EllipsisVIcon from '@components/deprecatedTookit/icons/fa/solid/EllipsisVIcon';
import './style.css';

function DepartmentDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const toggleIsDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteDepartment = (e) => {
    e.stopPropagation();
    props.handleDelete();
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsDelete(false);
  };

  let options;

  const className = 'department-dropdown';

  if (!isDelete) {
    options = (
      <div className={`${className}-options-wrapper`}>
        <div className={`${className}-options ${className}-options-delete`} onClick={toggleIsDelete}>
          <h3>Delete Account</h3>
        </div>
        <div className={`${className}-options`} onClick={toggleOpen}>
          <h3>Close Menu</h3>
        </div>
      </div>
    );
  } else {
    options = (
      <div className={`${className}-options-wrapper`} onClick={toggleIsDelete}>
        <div className={`${className}-options`} onClick={toggleIsDelete}>
          <h3>No, Keep Account</h3>
        </div>
        <div className={`${className}-options ${className}-options-delete`} onClick={handleDeleteDepartment}>
          <h3>Yes, Delete Account</h3>
        </div>
      </div>
    );
  }

  return (
    <div className={`${className}-wrapper`}>
      <div className={`${className}-icon-wrapper`} onClick={toggleOpen}>
        <EllipsisVIcon className={`${className}-icon`} />
      </div>
      {isOpen && options}
    </div>
  );
}

DepartmentDropdown.propTypes = {
  handleDelete: PropTypes.func
};

export default DepartmentDropdown;
