// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvoicingRules {
  width: 100%;
  padding-right: 12px;
}

  .InvoicingRules .flex {
    display: flex;
  }

  .InvoicingRules .justify-center {
    justify-content: center;
  }

  .InvoicingRules .justify-between {
    justify-content: space-between;
  }

  .InvoicingRules .items-center {
    align-items: center;
  }

  .InvoicingRules .height-8 {
    height: 8px;
  }

  .InvoicingRules .height-24 {
    height: 24px;
  }

  .InvoicingRules .height-32 {
    height: 32px;
  }

  .InvoicingRules .w-full {
    width: 100%;
  }

  .InvoicingRules .width-24 {
    width: 24px;
  }

  .InvoicingRules .cursor-pointer {
    cursor: pointer;
  }

  .InvoicingRules .invoicingRulesHeading {
    font-weight: 800;
    font-size: 14px;
  }

  .InvoicingRules .attributeLabel {
    font-weight: 800;
    font-size: 12px;
    color: #848484;
  }

  .InvoicingRules .gridContainer {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .InvoicingRules .plusCircleIconContainer {
    height: 16px;
    width: 16px;
  }

  .InvoicingRules .plusCircleIcon {
    color: #9fa2a5;
  }

  .InvoicingRules .plusCircleIconButton:hover .plusCircleIcon,
  .InvoicingRules .plusCircleIconButton:focus .plusCircleIcon {
    color: #41a741;
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./app/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarInvoicingRules/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AAyErB;;EAvEE;IACE,aAAa;EACf;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,QAAQ;IACR,gDAAgD;EAClD;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;;IAEE,cAAc;IACd,eAAe;EACjB","sourcesContent":[".InvoicingRules {\n  width: 100%;\n  padding-right: 12px;\n\n  .flex {\n    display: flex;\n  }\n\n  .justify-center {\n    justify-content: center;\n  }\n\n  .justify-between {\n    justify-content: space-between;\n  }\n\n  .items-center {\n    align-items: center;\n  }\n\n  .height-8 {\n    height: 8px;\n  }\n\n  .height-24 {\n    height: 24px;\n  }\n\n  .height-32 {\n    height: 32px;\n  }\n\n  .w-full {\n    width: 100%;\n  }\n\n  .width-24 {\n    width: 24px;\n  }\n\n  .cursor-pointer {\n    cursor: pointer;\n  }\n\n  .invoicingRulesHeading {\n    font-weight: 800;\n    font-size: 14px;\n  }\n\n  .attributeLabel {\n    font-weight: 800;\n    font-size: 12px;\n    color: #848484;\n  }\n\n  .gridContainer {\n    display: grid;\n    gap: 8px;\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n  }\n\n  .plusCircleIconContainer {\n    height: 16px;\n    width: 16px;\n  }\n\n  .plusCircleIcon {\n    color: #9fa2a5;\n  }\n\n  .plusCircleIconButton:hover .plusCircleIcon,\n  .plusCircleIconButton:focus .plusCircleIcon {\n    color: #41a741;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
