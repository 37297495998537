import { useState, useContext } from 'react';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import Button from '@client/components/Button/component';
import { appUIActionContext } from '@client/components/App/contexts';
import { toggleAppModal } from '@client/state/ui/actions';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast/component';
import Department from '@client/_blessed/store/entities/departments/model';
import { type ARAccessorialFee } from '../../component';
import { type AccessorialRuleGroup } from '../../container';
import { updateActiveAccessorialRuleGroup } from '../../actions';
import {
  CREATE_CUSTOM_REQUEST,
  CREATE_CUSTOM_SUCCESS,
  CREATE_CUSTOM_ERROR,
  UPDATE_ACTIVE_REQUEST,
  UPDATE_ACTIVE_SUCCESS,
  UPDATE_ACTIVE_ERROR
} from '../../reducer';
import './style.css';

interface CheckboxWithLabelProps {
  id?: string;
  label: string;
  onChange: any;
  isChecked: boolean;
  isDisabled?: boolean;
}
function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  const { id, isChecked, isDisabled, label, onChange } = props;

  const checkboxId = id || label;
  return (
    <>
      <input
        type="checkbox"
        id={checkboxId}
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
        aria-label={`${label} checkbox`}
      />

      <label style={{ paddingLeft: 8 }} htmlFor={checkboxId}>
        {label}
      </label>
    </>
  );
}

function createCustomAccessorialGroup(
  cneeUUID: string,
  name: string,
  additionalFees: number,
  accessorials: string[]
): any {
  const url = api('/accessorial-rule-groups');
  const payload = { additionalFees, name, cneeUUID, accessorials };
  const body = JSON.stringify(payload);
  const headers = { 'Content-Type': 'application/json' };
  const opts = { method: 'POST', body, headers };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

export interface InvoicingRulesModalProps {
  arAccessorialFees: ARAccessorialFee[];
  accessorialRuleGroup: AccessorialRuleGroup | null | undefined;
  consignee: Department;
  customer: Department;
  dispatch: any;
  errorWhileCreating: any;
  errorWhileUpdating: any;
}

function InvoicingRulesModal(props: InvoicingRulesModalProps) {
  const { customer, consignee, arAccessorialFees, accessorialRuleGroup, dispatch } = props;

  const accessorials = accessorialRuleGroup?.accessorials || [];
  const attributes = accessorialRuleGroup?.attributes;

  const cneeName = consignee?.name || 'N/A';
  const defaultCustomRuleGroupName = `Custom Rule for ${cneeName}`;
  const ruleGroupName = attributes?.name || defaultCustomRuleGroupName;

  const [accessorialRuleGroupName, setAccessorialRuleGroupName] = useState(ruleGroupName);
  const [additionalFees, setAdditionalFees] = useState(attributes?.additionalFees || 0);
  const includedFeeTypes = accessorials.map((x) => x.feeType);
  const [checkedFeeTypes, setCheckedFeeTypes] = useState(includedFeeTypes);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const executeAppUIAction = useContext(appUIActionContext);

  // The `AppModal` doesn't rerender when props change. See DA-7527 for more info.
  // Kludge: Use props to initialize local component state.
  const [errorWhileCreating, setErrorWhileCreating] = useState(props.errorWhileCreating);
  const [errorWhileUpdating, setErrorWhileUpdating] = useState(props.errorWhileUpdating);

  const closeModal = (): void => {
    executeAppUIAction(toggleAppModal(false));
  };

  const hasActiveRuleGroup = !!accessorialRuleGroup?.attributes?.uuid;
  const actionType = hasActiveRuleGroup ? 'Edit' : 'Create';

  const customerName = customer?.name || 'N/A';

  return (
    <div className="InvoicingRulesModal modalContainer">
      <div className="modalHeading">{actionType} Consignee Level Rate Type</div>
      <div className="height-32" />

      <div className="gridContainer">
        <div className="infoContainer">
          <div>Shipper</div>
          <div className="infoValue font-bold">{customerName}</div>
        </div>
        <div className="infoContainer">
          <div className="infoLabel">Consignee</div>
          <div className="infoValue font-bold">{cneeName}</div>
        </div>

        <div className="inputContainer">
          <div className="infoLabel">Rate Type</div>
          <div className="flex">
            <input
              type="text"
              className="flex-1 font-bold"
              value={accessorialRuleGroupName}
              onChange={(e) => {
                const { value } = e.target;
                setAccessorialRuleGroupName(value);
              }}
              aria-label="Rate type name"
            />
          </div>
        </div>

        <div className="infoContainer">
          <div className="infoLabel">Level</div>
          <div className="infoValue font-bold">Consignee</div>
        </div>

        {/*
          // Not part of MVP
          <div className="inputContainer">
            <div className="infoLabel" >Chassis Free Days</div>
            <div className="infoValue">5</div>
          </div>
          */}

        <div className="inputContainer">
          <div className="infoLabel">Additional Fees</div>
          <div className="flex">
            <span className="font-bold">$</span>
            <input
              type="number"
              value={additionalFees}
              className="flex-1 font-bold"
              onChange={(e) => {
                const { value } = e.target;
                const parsedValue = parseInt(value, 10);
                setAdditionalFees(parsedValue);
              }}
              aria-label="Additional fees"
            />
          </div>
        </div>
      </div>
      <div className="height-32" />
      <div className="accessorialHeading">Accessorials Included: </div>
      <div className="height-24" />
      <div className="accessorialGridContainer overflow-y-scroll">
        {arAccessorialFees.map((x: ARAccessorialFee) => {
          const { feeType } = x.attributes;
          const isChecked = checkedFeeTypes.includes(feeType);

          const onChange = () => {
            let copyOfCheckedFeeTypes = checkedFeeTypes.slice();

            if (isChecked) {
              copyOfCheckedFeeTypes = copyOfCheckedFeeTypes.filter((ft) => ft !== feeType);
            } else {
              copyOfCheckedFeeTypes.push(feeType);
            }

            setCheckedFeeTypes(copyOfCheckedFeeTypes);
          };

          return (
            <div key={feeType} className="checkboxWithLabelContainer">
              <CheckboxWithLabel label={x.attributes.name} onChange={onChange} isChecked={isChecked} />
            </div>
          );
        })}
      </div>
      <div className="height-24" />
      <div className="buttonRowContainer">
        <Button className="" label="Cancel" onClick={closeModal} />
        <div className="width-16" />
        <Button
          loading={isSubmitting}
          theme="2"
          label="Save"
          disabled={isSubmitting}
          onClick={() => {
            const cneeUUID = consignee.uuid;

            dispatch({ type: CREATE_CUSTOM_REQUEST });

            setIsSubmitting(true);

            // Create new custom rule group
            createCustomAccessorialGroup(cneeUUID, accessorialRuleGroupName, additionalFees, checkedFeeTypes).then(
              (newRuleGroupData: any) => {
                raiseToast(
                  <SystemToast type={SystemToast.Type.SUCCESS} message="New rate type was successfully created" />
                );

                dispatch({ type: CREATE_CUSTOM_SUCCESS, data: newRuleGroupData });
                dispatch({ type: UPDATE_ACTIVE_REQUEST });

                const newRuleGroupUuid = newRuleGroupData.data.attributes.uuid;
                // Set newly created rule group as active rule group
                updateActiveAccessorialRuleGroup(cneeUUID, newRuleGroupUuid).then(
                  (updatedRuleGroupData) => {
                    raiseToast(
                      <SystemToast type={SystemToast.Type.SUCCESS} message="Rate type was successfully updated" />
                    );
                    dispatch({ type: UPDATE_ACTIVE_SUCCESS, data: updatedRuleGroupData });
                    setIsSubmitting(false);

                    closeModal();
                  },
                  (error: any) => {
                    raiseToast(
                      <SystemToast
                        type={SystemToast.Type.ERROR}
                        message="Something went wrong while setting the new rate type to be the active rate type"
                      />
                    );
                    console.log({ e: error });
                    dispatch({ type: UPDATE_ACTIVE_ERROR, error });

                    // Kludge: Duplicate state in both parent container component and local component
                    // See DA-7527 for more info.
                    setErrorWhileUpdating(error);

                    setIsSubmitting(false);
                  }
                );
              },
              (error: any) => {
                raiseToast(
                  <SystemToast
                    type={SystemToast.Type.ERROR}
                    message="Something went wrong while creating the new rate type"
                  />
                );

                console.log({ e: error });
                dispatch({ type: CREATE_CUSTOM_ERROR, error });

                // Kludge: Duplicate state in both parent container component and local component
                // See DA-7527 for more info.
                setErrorWhileCreating(error);

                setIsSubmitting(false);
              }
            );
          }}
        />
      </div>
      {errorWhileCreating ? (
        <>
          <div className="height-24" />
          <div>Something went wrong while creating the new custom rate type.</div>
        </>
      ) : null}
      {errorWhileUpdating ? (
        <>
          <div className="height-24" />
          <div>Something went wrong while setting the new custom rate type as the active rate type.</div>
        </>
      ) : null}
    </div>
  );
}

export default InvoicingRulesModal;
