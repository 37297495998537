// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.department-sidebar-delivery-preferences-wrapper {
    width: 100%;
    position: relative;
  }
  .department-sidebar-delivery-preferences-receiving-hours-sidebar-header {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .department-sidebar-delivery-preferences-receiving-hours-sidebar-header-switch {
      position: relative;
    }
  .department-sidebar-delivery-preferences-receiving-hours-sidebar-header-switch-wrapper {
        max-height: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
  .department-sidebar-delivery-preferences-same-imex-comfirmation {
    display: flex;
    max-height: 25px;
    align-items: center;
  }
  .department-sidebar-delivery-preferences-same-imex-comfirmation-button {
      margin-right: 8px;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
    }
  .department-sidebar-delivery-preferences-same-imex-comfirmation-button:hover {
        color: var(--color-green-8);
      }
  .department-sidebar-delivery-preferences-receiving-hours-sidebar-title {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .department-sidebar-delivery-preferences-spinner {
    position: absolute;
    right: 50%;
    bottom: 50%;
    width: 30px;
    height: 30px;
  }
  .department-sidebar-delivery-preferences-copy-options-wrapper {
    display: flex;
  }
  .department-sidebar-delivery-preferences-receiving-hours-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`, "",{"version":3,"sources":["webpack://./app/components/DepartmentDetailSidebar/components/DepartmentDetailSidebarDeliveryPreferences/style.css"],"names":[],"mappings":"AACE;IACE,WAAW;IACX,kBAAkB;EACpB;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EAYrB;EAVE;MACE,kBAAkB;IAQpB;EANE;QACE,gBAAgB;QAChB,aAAa;QACb,2BAA2B;QAC3B,mBAAmB;MACrB;EAIJ;IACE,aAAa;IACb,gBAAgB;IAChB,mBAAmB;EAYrB;EAVE;MACE,iBAAiB;MACjB,0BAA0B;MAC1B,eAAe;MACf,gBAAgB;IAKlB;EAHE;QACE,2BAA2B;MAC7B;EAIJ;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;EAClB;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,WAAW;IACX,YAAY;EACd;EAEA;IACE,aAAa;EACf;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;EACxB","sourcesContent":[".department-sidebar-delivery-preferences {\n  &-wrapper {\n    width: 100%;\n    position: relative;\n  }\n\n  &-receiving-hours-sidebar-header {\n    padding-top: 5px;\n    padding-bottom: 5px;\n\n    &-switch {\n      position: relative;\n\n      &-wrapper {\n        max-height: 25px;\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n      }\n    }\n  }\n\n  &-same-imex-comfirmation {\n    display: flex;\n    max-height: 25px;\n    align-items: center;\n\n    &-button {\n      margin-right: 8px;\n      text-decoration: underline;\n      cursor: pointer;\n      font-weight: 500;\n\n      &:hover {\n        color: var(--color-green-8);\n      }\n    }\n  }\n\n  &-receiving-hours-sidebar-title {\n    margin-left: 10px;\n    font-size: 14px;\n    font-weight: 400;\n  }\n\n  &-spinner {\n    position: absolute;\n    right: 50%;\n    bottom: 50%;\n    width: 30px;\n    height: 30px;\n  }\n\n  &-copy-options-wrapper {\n    display: flex;\n  }\n\n  &-receiving-hours-section {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
