import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Department from '@client/_blessed/store/entities/departments/model';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { selectLegsByOrderUUID } from '../../../../_blessed/components/features/Dispatch/store';
import { fetchDepartmentById, selectDepartmentsById, selectStopsByLegUUID } from '../../../../_blessed/store/entities';
import DepartmentDetailSidebarDeliveryPreferences from '../DepartmentDetailSidebarDeliveryPreferences';
import Instruction from '../../../Instruction';
import { appActionContext, appStateContext } from '../../../App/contexts';
import { updateDepartments } from '../../../../state/resources/departments/actions';
import { updateStops } from '../../../../state/resources/stops/actions';
import { retrieveOrderById } from '../../../../state/resources/orders/actions';
import { updateStopInstants } from '../../../../state/resources/stop-instants/actions';
import PhoneButton from '../../../PhoneButton';

import './style.css';

interface DepartmentDetailSidebarSOPProps {
  department: Department;
  departmentType: string;
  departmentId: string;
  title: string;
  stopInstant: any;
  stop: any;
  orderId?: string;
}

function DepartmentDetailSidebarSOP({
  department,
  departmentType,
  departmentId,
  title,
  stopInstant,
  stop,
  orderId
}: DepartmentDetailSidebarSOPProps) {
  const dispatch = useAppDispatch();
  const appDispatch = useContext(appActionContext);
  const appState = useContext(appStateContext);

  const order = (orderId && appState['order.id']?.[orderId]) || null;

  const legsByOrderUUID = useSelector(selectLegsByOrderUUID);
  const departmentsById = useSelector(selectDepartmentsById);

  const orderLegs = order ? legsByOrderUUID[order.id] : [];

  let consignee: Department | null = null;

  // eslint-disable-next-line no-restricted-syntax
  for (const leg of orderLegs) {
    if (consignee) {
      break;
    }

    const legStopsByUuid = useSelector(selectStopsByLegUUID);
    const legStops = legStopsByUuid[leg.uuid];
    // eslint-disable-next-line no-restricted-syntax
    for (const legStop of legStops) {
      const dept = departmentsById?.[legStop.departmentUUID];
      if (dept && dept.type === 'consignee') {
        consignee = dept;
        break;
      }
    }
  }

  const billingAccountId = order?.relationships?.billingAccount?.data?.id;

  const shipper = billingAccountId ? departmentsById?.[billingAccountId] : null;

  const baseClassName = 'department-detail-sidebar-sop';
  const className = baseClassName;
  const isAccount = title === 'Account';
  const isConsignee = departmentType === 'consignee';
  const isPickup = stopInstant ? stopInstant.type === 'departure' : false;
  const isDropoff = stopInstant ? stopInstant.type === 'arrival' : false;

  const showPickupInstructions = isPickup || isAccount;
  const showDropoffInstructions = isDropoff || isAccount;

  const updateField = async (
    value: any,
    {
      dataResourceType,
      dataId,
      dataField,
      dataFieldTypeName
    }: { dataResourceType: string; dataId: string; dataField: string; dataFieldTypeName: string }
  ) => {
    const item: Record<string, any> = {
      type: dataResourceType,
      id: dataId
    };

    if (dataFieldTypeName === 'relationships') {
      item.relationships = {
        [dataField]: {
          data: {
            type: dataField,
            id: value
          }
        }
      };
    } else {
      item.attributes = {
        [dataField]: value
      };
    }

    if (isAccount) {
      await updateDepartments([item])();
      await dispatch(fetchDepartmentById({ departmentUuid: departmentId }));
    } else {
      if (dataResourceType === 'stop') {
        await updateStops([item])();
      } else if (dataResourceType === 'stopInstant') {
        await updateStopInstants([item])();
      }

      await appDispatch(retrieveOrderById(orderId));
    }
  };

  const handleOnSave = (value: any, inputProps: any) => {
    updateField(value, inputProps);
  };

  const shipperContact =
    shipper && shipper.contactNumber && shipper.contactNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  const consigneeContact =
    consignee && consignee.contactNumber && consignee.contactNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return (
    <div className={className}>
      <div className={`${baseClassName}__section`}>
        <div className={`${baseClassName}__section-title`}>Driver Instruction</div>
        {showDropoffInstructions && (
          <Instruction
            dataField={isAccount ? 'deliveryInstructions' : 'driverInstructions'}
            dataResourceType={isAccount ? 'department' : 'stopInstant'}
            dataId={isAccount ? department.uuid : stopInstant.uuid}
            label="Dropoff Instruction"
            isAccount={isAccount}
            onSave={handleOnSave}
            defaultText={department.deliveryInstructions}
            stopText={isAccount ? department.deliveryInstructions : stopInstant.driverInstructions}
          />
        )}
        {showPickupInstructions && (
          <Instruction
            dataField={isAccount ? 'pickupInstructions' : 'driverInstructions'}
            dataResourceType={isAccount ? 'department' : 'stopInstant'}
            dataId={isAccount ? department.uuid : stopInstant.uuid}
            label="Pickup Instruction"
            isAccount={isAccount}
            onSave={handleOnSave}
            defaultText={department.pickupInstructions}
            stopText={isAccount ? department.pickupInstructions : stopInstant.driverInstructions}
          />
        )}
      </div>
      <div className={`${baseClassName}__section`}>
        <div className={`${baseClassName}__section-title`}>Ops Instruction</div>
        <Instruction
          dataField="opsInstructions"
          dataResourceType={isAccount ? 'department' : 'stop'}
          dataId={isAccount ? department.uuid : stop.uuid}
          label="Ops Instruction"
          isAccount={isAccount}
          onSave={handleOnSave}
          defaultText={department.opsInstructions}
          stopText={isAccount ? department.opsInstructions : stop.opsInstructions}
        />
      </div>
      <div className={`${baseClassName}__section`}>
        <div className={`${baseClassName}__section-title`}>Contact Info</div>
        <div className={`${baseClassName}__contact-container`}>
          <span className={`${baseClassName}__section-subtitle`}>Shipper Contact</span>
          <div className={`${baseClassName}__contact-details`}>
            <span className={`${baseClassName}__contact`}>{shipperContact || 'N/A'}</span>
            {shipperContact && (
              <PhoneButton id="shipper-contact-phone" copyValue={shipperContact} toolTipValue={shipperContact} />
            )}
          </div>
        </div>
        <div className={`${baseClassName}__contact-container`}>
          <span className={`${baseClassName}__section-subtitle`}>Consignee Contact</span>
          <div className={`${baseClassName}__contact-details`}>
            <span className={`${baseClassName}__contact`}>{consigneeContact || 'N/A'}</span>
            {consigneeContact && (
              <PhoneButton id="consignee-contact-phone" copyValue={consigneeContact} toolTipValue={consigneeContact} />
            )}
          </div>
        </div>
      </div>
      {isConsignee && (
        <div className={`${baseClassName}__section-del-prefs`}>
          <div className={`${baseClassName}__section-title`}>Consignee Preferences</div>
          <DepartmentDetailSidebarDeliveryPreferences hidePreview editable={isAccount} departmentId={departmentId} />
        </div>
      )}
    </div>
  );
}

DepartmentDetailSidebarSOP.propTypes = {
  title: PropTypes.string,
  department: PropTypes.object,
  departmentType: PropTypes.string,
  departmentId: PropTypes.string,
  stopInstant: PropTypes.object,
  stop: PropTypes.object,
  orderId: PropTypes.string
};

export default DepartmentDetailSidebarSOP;
