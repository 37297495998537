/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import PlusIcon from '@components/deprecatedTookit/icons/fa/regular/PlusIcon';
import PlusCircleIcon from '@components/deprecatedTookit/icons/fa/regular/PlusCircleIcon';
import TimesCircleIcon from '@components/deprecatedTookit/icons/fa/regular/TimesCircleIcon';
import AutoResizer, { Size } from 'react-virtualized-auto-sizer';
import copy from 'clipboard-copy';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import TrashIcon from '@components/deprecatedTookit/icons/fa/light/TrashAltIcon';
import CopyIcon from '@components/deprecatedTookit/icons/fa/regular/CopyIcon';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import LoadingSpinner from '@components/deprecatedTookit/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectDepartmentsById, selectDepartmentsLoading } from '@client/_blessed/store/entities/departments';
import { selectAllUsers, selectUsersLoadingByDepartmentUuid, setLocalUserValue } from '@client/_blessed/store/entities';
import { User } from '@client/_blessed/store/entities/users/model';
import TextInput from '../../../TextInput';
import EditableField from '../../../EditableField';
import DropdownSelectInput from '../../../DropdownSelectInput';
import { addUserAndRoleToDepartment, removeUserFromDepartment } from '../../../../state/resources/departments/actions';
import { patchUserRelationRole } from '../../../../state/resources/roles/actions';
import { updateUsers } from '../../../../state/resources/users/actions';
import { appActionContext, appStateContext } from '../../../App/contexts';
import Button from '../../../Button';
import Table from '../../../Table';
import TableBodyCell from '../../../Table/components/TableBodyCell';
import { createPeopleTableCol, createSuspendUntilTableCol } from '../../../Table/columns';
import { raiseToast, TOAST_POSITION } from '../../../Toaster';
import SystemToast from '../../../SystemToast';

import './style.css';

interface RowArgs {
  cellData: any;
  column: any;
  rowData: User & { userSelector: boolean; roleSelector: boolean };
}

interface DepartmentDetailSidebarPeopleProps {
  departmentId: string;
  users: User[];
  roles: any[];
  onLoadUsers: (...args: any) => any;
}

interface DepartmentDetailSidebarPeopleState {
  isFetching: boolean;
  isLoading: Record<string, boolean>;
  errors: any;
  isAddingUser: boolean;
  addUserNameDropdownValues: {
    value: string | null;
    label: string | null;
    userObject: User | null;
  };
  addUserRoleDropdownValues: {
    id: string | null;
    name: string | null;
  };
}

function dateTimeToDateDisplayFormatter(value?: any | null) {
  return value ? moment(value).format('MM/DD/YYYY') : '';
}

function DepartmentDetailSidebarPeople(props: DepartmentDetailSidebarPeopleProps) {
  const appDispatch = useAppDispatch();
  const appActionDispatch = useContext(appActionContext);
  const appState = useContext(appStateContext);
  const [state, setState] = useState<DepartmentDetailSidebarPeopleState>({
    isFetching: false,
    isLoading: {
      addUser: false
    },
    errors: null,
    isAddingUser: false,
    addUserNameDropdownValues: {
      value: null,
      label: null,
      userObject: null
    },
    addUserRoleDropdownValues: {
      id: null,
      name: null
    }
  });
  const className = 'department-detail-sidebar-people';

  const departmentsLoading = useSelector(selectDepartmentsLoading);
  const departmentsById = useSelector(selectDepartmentsById);

  const usersLoading = useSelector(selectUsersLoadingByDepartmentUuid);
  const allUsers = useSelector(selectAllUsers);

  const department = departmentsById[props.departmentId];

  const allUsersForDepartment = useMemo(() => {
    if (!department) {
      return [];
    }

    return allUsers.reduce((accum, user) => {
      if (user.companyUuid === department.companyUUID) {
        accum.push(user);
      }

      return accum;
    }, [] as User[]);
  }, [allUsers]);

  if (departmentsLoading || (usersLoading && !allUsers) || !department) {
    return <LoadingScreen />;
  }

  const departmentType = department.type;
  const isCarrier = departmentType === 'carrier';

  const usersWithState = props.users.map((user) => ({
    ...user,
    isLoading: (state.isLoading as any)[user.uuid]
  }));

  // filtering out users that already belong to department
  const userUuids = props.users.map((user) => user.uuid);
  const nonDepartmentUsers = allUsersForDepartment.filter((user) => !userUuids.includes(user.uuid));

  // formatting non-department users for dropdown
  const formattedNonDepartmentUsers =
    Array.isArray(nonDepartmentUsers) &&
    nonDepartmentUsers.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.uuid,
      userObject: JSON.parse(JSON.stringify(user))
    }));

  const updateUserField = async (value: any, { dataResourceType, dataId, dataField }: any) => {
    const item: Record<string, any> = {
      type: dataResourceType,
      id: dataId,
      attributes: {}
    };

    item.attributes[dataField] = value;

    await updateUsers([item])();
  };

  const handleInputSubmit = async (value: any, dataProps: any) => {
    await updateUserField(value, dataProps);
    return props.onLoadUsers();
  };

  const handleRoleUpdate = async (value: any, cProps: any) => {
    const fieldValue = value[0][cProps.valueField];
    const userRelationId = cProps.dataId;

    await appActionDispatch(patchUserRelationRole(userRelationId, fieldValue));
  };

  const handleAddUserDropdownChange = (stateProp: any, value: any) => {
    let updatedValue = value[0];
    if (stateProp === 'addUserNameDropdownValues' && !updatedValue) {
      updatedValue = {
        value: null,
        label: null,
        userObject: null
      };
    }

    setState((prevState) => ({
      ...prevState,
      [stateProp]: updatedValue
    }));
  };

  const createAddUserRow = () => {
    setState((prevState) => ({
      ...prevState,
      isAddingUser: true
    }));
  };

  const handleAddUser = async (userId: string, roleId: string, userObject: User) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: {
          ...prevState.isLoading,
          addUser: true
        }
      }));
      const response = await appActionDispatch(addUserAndRoleToDepartment(userId, props.departmentId, roleId));
      const newUserRelation = {
        ...response.data[0].attributes,
        uuid: response.data[0].id
      };
      appDispatch(
        setLocalUserValue({
          userUuid: userObject.uuid,
          userField: 'UserRelation',
          value: newUserRelation
        })
      );

      setState((prevState: any) => ({
        ...prevState,
        isAddingUser: false,
        isLoading: {
          ...prevState.isLoading,
          addUser: false
        },
        addUserNameDropdownValues: {
          value: null,
          label: null
        },
        addUserRoleDropdownValues: {
          id: null,
          name: null
        }
      }));
      props.onLoadUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveUser = async (userId: string) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        [userId]: true
      }
    }));

    await appActionDispatch(removeUserFromDepartment(userId, props.departmentId));

    props.onLoadUsers();

    setState((prevState) => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        [userId]: false
      }
    }));
  };

  const handleCancelAdd = () => {
    setState((prevState: any) => ({
      ...prevState,
      isAddingUser: false,
      addUserNameDropdownValues: {
        value: null,
        label: null
      },
      addUserRoleDropdownValues: {
        id: null,
        name: null
      }
    }));
  };

  const copyEmailsToClipboard = () => {
    const lastIndex = props.users.length - 1;
    let emailString = '';

    props.users.forEach((user, index) => {
      emailString += user.email1;

      if (index < lastIndex) {
        emailString += ', ';
      }
    });

    copy(emailString);
    raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Emails have been copied to clipboard" />, {
      position: TOAST_POSITION.BOTTOM_LEFT
    });
  };

  // creating table columns
  const tableColumns = [
    createPeopleTableCol({
      isFirstOfGroup: true,
      title: 'Name',
      key: 'name',
      width: 200,
      dataGetter: (args: RowArgs) => args.rowData.userSelector || `${args.rowData.firstName} ${args.rowData.lastName}`
    }),
    createPeopleTableCol({
      title: 'Role',
      key: 'role',
      width: 240,
      dataGetter: (args: RowArgs) =>
        args.rowData.roleSelector || args.rowData.UserRelation?.roleUuid || args.rowData.departments?.[0].roleUuid,
      cellRenderer: (args: RowArgs) => {
        if (!args.rowData.roleSelector) {
          let roleUIDisplay;
          let userRelationId;
          if (!args.cellData) {
            roleUIDisplay = null;
          } else if (appState['roles.id']) {
            roleUIDisplay = appState['roles.id'][args.cellData].attributes.name;
          }
          if (args.rowData && args.rowData && args.rowData.UserRelation) {
            userRelationId = args.rowData.UserRelation.uuid;
          }
          return (
            <TableBodyCell
              isFirstField={args.column.isFirstOfGroup}
              {...args}
              key={`role-user-${args.rowData.uuid}`}
              className="r-table__cell"
            >
              <DropdownSelectInput
                values={[{ id: args.cellData, name: roleUIDisplay }]}
                options={props.roles}
                labelField="name"
                valueField="id"
                placeholder="Role"
                dataId={userRelationId}
                onChange={handleRoleUpdate}
              />
            </TableBodyCell>
          );
        }
        return (
          <TableBodyCell {...args} key={`role-user-${args.rowData.uuid}`} className="r-table__cell">
            {args.cellData}
          </TableBodyCell>
        );
      }
    }),
    createPeopleTableCol({
      title: 'Phone',
      key: 'phone',
      width: 120,
      dataGetter: (args: RowArgs) => {
        if (!args.rowData.userSelector) {
          return args.rowData.mobile_phone;
        }
      },
      cellRenderer: (args: RowArgs) => (
        <TableBodyCell {...args} className="r-table__cell">
          {!args.rowData.userSelector && (
            <TextInput
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="mobile_phone"
              dataResourceType="user"
              dataId={args.rowData.uuid}
              onSubmit={handleInputSubmit}
            />
          )}
        </TableBodyCell>
      )
    }),
    createPeopleTableCol({
      title: 'Email',
      key: 'email',
      width: 200,
      dataGetter: (args: RowArgs) => {
        if (!args.rowData.userSelector) {
          return args.rowData.email1;
        }
      },
      cellRenderer: (args: RowArgs) => (
        <TableBodyCell {...args} className="r-table__cell">
          {!args.rowData.userSelector && (
            <TextInput
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="email1"
              dataResourceType="user"
              dataId={args.rowData.uuid}
              onSubmit={handleInputSubmit}
            />
          )}
        </TableBodyCell>
      )
    })
  ];

  if (isCarrier) {
    tableColumns.push(
      createPeopleTableCol({
        title: 'CDL',
        key: 'license',
        width: 120,
        dataGetter: (args: RowArgs) => {
          if (!args.rowData.userSelector) {
            return args.rowData.driverCDL;
          }
        },
        cellRenderer: (args: RowArgs) => (
          <TableBodyCell {...args} className="r-table__cell">
            {!args.rowData.userSelector && (
              <TextInput
                className="people-detail-sidebar__input-field"
                value={args.cellData}
                dataField="driverCDL"
                dataResourceType="user"
                dataId={args.rowData.uuid}
                onSubmit={handleInputSubmit}
              />
            )}
          </TableBodyCell>
        )
      }),
      createPeopleTableCol({
        title: 'CDL Expiration',
        key: 'cdl_expiration',
        dataGetter: (args: RowArgs) => {
          if (!args.rowData.userSelector) {
            return args.rowData.driverCDLExpiration;
          }
        },
        cellRenderer: (args: RowArgs) => (
          <TableBodyCell {...args} className="r-table__cell">
            {!args.rowData.userSelector && (
              <EditableField
                type="date"
                placeholder="CDL Expiration"
                editable
                displayFormatter={dateTimeToDateDisplayFormatter}
                dataId={args.rowData.uuid}
                dataField="driverCDLExpiration"
                dataResourceType="user"
                value={args.cellData}
                onChange={handleInputSubmit}
              />
            )}
          </TableBodyCell>
        )
      })
    );
  }

  tableColumns.push(
    createPeopleTableCol(
      createSuspendUntilTableCol({
        editable: true,
        flexGrow: 1,
        dataGetter: (args: RowArgs) => {
          if (!args.rowData.userSelector) {
            return args.rowData && args.rowData.suspendedUntil;
          }
        },
        onDataChange: updateUserField
      })
    )
  );

  tableColumns.push(
    createPeopleTableCol({
      title: null,
      key: 'icon',
      sortable: false,
      width: 65,
      dataGetter: (args: any) => {
        if (args.rowData.userSelector) {
          const { userId, roleId, userObject, isLoading } = args.rowData;

          return (
            <>
              {isLoading ? (
                <LoadingSpinner className={`${className}-plus-icon`} />
              ) : (
                <PlusCircleIcon
                  className={`${className}-plus-icon`}
                  style={{ color: userId && roleId ? '#24b248' : '#adb5bd' }}
                  onClick={userId && roleId ? () => handleAddUser(userId, roleId, userObject) : () => {}}
                />
              )}
              <TimesCircleIcon className={`${className}-times-icon`} onClick={handleCancelAdd} />
            </>
          );
        }
        const { isLoading } = args.rowData;

        return isLoading ? (
          <LoadingSpinner className={`${className}-plus-icon`} />
        ) : (
          <TrashIcon
            className={`${className}-trash-icon`}
            onClick={() => {
              handleRemoveUser(args.rowData.uuid);
            }}
          />
        );
      }
    })
  );

  const addUserRow = [
    {
      userSelector: (
        <DropdownSelectInput
          values={[state.addUserNameDropdownValues]}
          options={formattedNonDepartmentUsers as any}
          labelField="label"
          valueField="value"
          placeholder="Name"
          onChange={(values) => handleAddUserDropdownChange('addUserNameDropdownValues', values)}
        />
      ),
      roleSelector: (
        <DropdownSelectInput
          values={[state.addUserRoleDropdownValues]}
          options={props.roles}
          labelField="name"
          valueField="id"
          placeholder="Role"
          onChange={(values) => handleAddUserDropdownChange('addUserRoleDropdownValues', values)}
        />
      ),
      userId: state.addUserNameDropdownValues.value,
      roleId: state.addUserRoleDropdownValues.id,
      userObject: state.addUserNameDropdownValues.userObject,
      isLoading: state.isLoading.addUser
    }
  ];

  let componentWidth: string | number = 1450;
  if (!isCarrier) {
    componentWidth -= 285;
  }
  componentWidth = `${componentWidth}px`;

  return (
    <div className={className} style={{ width: componentWidth }}>
      {state.isFetching ? (
        <LoadingScreen className="sidebar-attachment-thumbnails__loading-screen" />
      ) : (
        <>
          <span className={`${className}__button-group`}>
            <Button
              theme="1"
              label="Add Existing People"
              icon={<PlusIcon />}
              disabled={state.isAddingUser}
              onClick={createAddUserRow}
            />
            <Button theme="1" label="Copy Email Addresses" icon={<CopyIcon />} onClick={copyEmailsToClipboard} />
          </span>
          <AutoResizer>
            {({ width, height }: Size) => (
              <Table
                fixed
                data={state.isAddingUser && !props.users.length ? addUserRow : usersWithState}
                frozenData={state.isAddingUser && props.users.length ? addUserRow : []}
                width={width}
                height={height}
                rowHeight={50}
                headerHeight={50}
                // defaultSortBy="name"
                columns={tableColumns}
              />
            )}
          </AutoResizer>
        </>
      )}
    </div>
  );
}

export default React.memo(DepartmentDetailSidebarPeople);
