/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AutoResizer from 'react-virtualized-auto-sizer';
import PlusIcon from '@components/deprecatedTookit/icons/fa/regular/PlusIcon';
import PlusCircleIcon from '@components/deprecatedTookit/icons/fa/regular/PlusCircleIcon';
import TimesCircleIcon from '@components/deprecatedTookit/icons/fa/regular/TimesCircleIcon';
import TrashIcon from '@components/deprecatedTookit/icons/fa/light/TrashAltIcon';
import LoadingSpinner from '@components/deprecatedTookit/LoadingSpinner';
import Toaster, { raiseToast } from '../../../Toaster';
import SystemToast from '../../../SystemToast';
import Table from '../../../Table';
import TableBodyCell from '../../../Table/components/TableBodyCell';
import { createCol } from '../../../Table/columns';
import { appActionContext } from '../../../App/contexts';
import Button from '../../../Button';
import getNestedObject from '../../../../utils/getNestedObject';
import TextInput from '../../../TextInput';
import { createTruck, updateTrucks, deleteTruck } from '../../../../state/resources/trucks/actions';

import './style.css';

function DepartmentDetailSidebarTrucking(props) {
  const appDispatch = useContext(appActionContext);
  const [state, setState] = useState({
    isLoading: {
      addTruck: false
    },
    isAdding: false,
    errors: null,
    dataItem: null,
    addTruckAttributes: {
      truckNickname: null,
      truckLicensePlate: null,
      truckRfid: null,
      truckVIN: null
    }
  });
  const baseClassName = 'department-detail-sidebar-trucks';

  const { departmentId } = props;

  const trucksWithState = props.trucks.map((truck) => ({ ...truck, isLoading: state.isLoading[truck.id] }));

  const createAddTruckRow = () => {
    setState((prevState) => ({
      ...prevState,
      isAdding: true
    }));
  };

  const handleCancelAdd = () => {
    setState((prevState) => ({
      ...prevState,
      isAdding: false,
      addTruckAttributes: {
        truckNickname: null,
        truckLicensePlate: null,
        truckRfid: null,
        truckVIN: null
      }
    }));
  };

  const handleAddTruck = async (attributes) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        addTruck: true
      }
    }));
    try {
      const response = await appDispatch(createTruck(attributes));
      const newTruck = getNestedObject(response, ['data', 0]);

      setState((prevState) => ({
        ...prevState,
        isAdding: false,
        isLoading: {
          ...prevState.isLoading,
          addTruck: false
        },
        addTruckAttributes: {
          truckNickname: null,
          truckLicensePlate: null,
          truckRfid: null,
          truckVIN: null
        }
      }));

      props.setParentState((prevState) => ({
        ...prevState,
        departmentTrucks: [...prevState.departmentTrucks, newTruck]
      }));

      props.onLoadTrucks();
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTruckInputChange = (attribute, value) => {
    setState((prevState) => ({
      ...prevState,
      addTruckAttributes: {
        ...prevState.addTruckAttributes,
        [attribute]: value
      }
    }));
  };

  const updateTruckField = async (value, { dataResourceType, dataId, dataField }) => {
    const item = {
      type: dataResourceType,
      id: dataId,
      attributes: {}
    };

    item.attributes[dataField] = value;

    if (['truckNickname', 'truckLicensePlate'].includes(dataField) && !value) {
      const label = dataField === 'truckNickname' ? 'Truck name' : 'Truck license plate';
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message={`${label} is required`} />, {
        position: Toaster.Position.BOTTOM_LEFT
      });
      return;
    }

    await updateTrucks([item])();
  };

  const handleRemoveTruck = async (truckId) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        [truckId]: true
      }
    }));

    await appDispatch(deleteTruck(truckId));

    props.setParentState((prevState) => ({
      ...prevState,
      departmentTrucks: prevState.departmentTrucks.filter((truck) => truck.id !== truckId)
    }));

    props.onLoadTrucks();

    setState((prevState) => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        [truckId]: false
      }
    }));
  };

  useEffect(
    () => () => {
      setState({
        isLoading: {
          addTruck: false
        },
        isAdding: false,
        errors: null,
        dataItem: null,
        addTruckAttributes: {
          truckNickname: null,
          truckLicensePlate: null,
          truckRfid: null,
          truckVIN: null
        }
      });
    },
    []
  );

  const addTruckRow = [
    {
      truckNameInput: (
        <TextInput disableAutoComplete onChange={(value) => handleAddTruckInputChange('truckNickname', value)} />
      ),
      plateInput: (
        <TextInput disableAutoComplete onChange={(value) => handleAddTruckInputChange('truckLicensePlate', value)} />
      ),
      rfidInput: <TextInput disableAutoComplete onChange={(value) => handleAddTruckInputChange('truckRfid', value)} />,
      vinInput: (
        <TextInput
          disableAutoComplete
          onChange={(value) => handleAddTruckInputChange('truckVIN', value)}
          maxLength={17}
        />
      ),
      inputTruckNickname: state.addTruckAttributes.truckNickname,
      inputTruckLicensePlate: state.addTruckAttributes.truckLicensePlate,
      inputTruckRfid: state.addTruckAttributes.truckRfid,
      inputTruckVIN: state.addTruckAttributes.truckVIN,
      isLoading: state.isLoading.addTruck
    }
  ];

  const tableColumns = [
    createCol({
      width: 200,
      isFirstOfGroup: true,
      title: 'Truck Name',
      key: 'truckNickname',
      dataGetter: (args) => args.rowData.truckNameInput || args.rowData.attributes.truckNickname,
      cellRenderer: (args) => (
        <TableBodyCell isFirstField={args.column.isFirstOfGroup} {...args} className="r-table__cell">
          {args.rowData.truckNameInput || (
            <TextInput
              disableAutoComplete
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="truckNickname"
              dataResourceType="truck"
              dataId={args.rowData.id}
              onSubmit={updateTruckField}
            />
          )}
        </TableBodyCell>
      )
    }),
    createCol({
      width: 90,
      title: 'Plate',
      key: 'truckLicensePlate',
      dataGetter: (args) => args.rowData.plateInput || args.rowData.attributes.truckLicensePlate,
      cellRenderer: (args) => (
        <TableBodyCell {...args} className="r-table__cell">
          {args.rowData.plateInput || (
            <TextInput
              disableAutoComplete
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="truckLicensePlate"
              dataResourceType="truck"
              dataId={args.rowData.id}
              onSubmit={updateTruckField}
            />
          )}
        </TableBodyCell>
      )
    }),
    createCol({
      width: 120,
      title: 'RFID',
      key: 'truckRfid',
      dataGetter: (args) => args.rowData.rfidInput || args.rowData.attributes.truckRfid,
      cellRenderer: (args) => (
        <TableBodyCell {...args} className="r-table__cell">
          {args.rowData.rfidInput || (
            <TextInput
              disableAutoComplete
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="truckRfid"
              dataResourceType="truck"
              dataId={args.rowData.id}
              onSubmit={updateTruckField}
            />
          )}
        </TableBodyCell>
      )
    }),
    createCol({
      width: 190,
      title: 'VIN',
      key: 'truckVIN',
      dataGetter: (args) => args.rowData.vinInput || args.rowData.attributes.truckVIN,
      cellRenderer: (args) => (
        <TableBodyCell {...args} className="r-table__cell">
          {args.rowData.vinInput || (
            <TextInput
              disableAutoComplete
              className="people-detail-sidebar__input-field"
              value={args.cellData}
              dataField="truckVIN"
              dataResourceType="truck"
              dataId={args.rowData.id}
              onSubmit={updateTruckField}
              maxLength={17}
            />
          )}
        </TableBodyCell>
      )
    }),
    createCol({
      title: null,
      key: 'icon',
      sortable: false,
      width: 65,
      dataGetter: (args) => {
        if (args.rowData.truckNameInput) {
          const { inputTruckNickname, inputTruckLicensePlate, inputTruckRfid, inputTruckVIN, isLoading } = args.rowData;
          const vinIsCorrectLength = inputTruckVIN && inputTruckVIN.length === 17;
          const allInputsFilled = inputTruckNickname && inputTruckLicensePlate && inputTruckRfid && vinIsCorrectLength;
          const attributes = {
            truckNickname: inputTruckNickname,
            truckLicensePlate: inputTruckLicensePlate,
            truckRfid: inputTruckRfid,
            truckVIN: inputTruckVIN,
            departmentUuid: departmentId
          };

          return (
            <>
              {isLoading ? (
                <LoadingSpinner className={`${baseClassName}__header-plus-icon`} />
              ) : (
                <PlusCircleIcon
                  className={`${baseClassName}__header-plus-icon`}
                  style={{ color: allInputsFilled ? '#24b248' : '#adb5bd' }}
                  onClick={allInputsFilled ? () => handleAddTruck(attributes) : () => {}}
                />
              )}
              <TimesCircleIcon className={`${baseClassName}-times-icon`} onClick={handleCancelAdd} />
            </>
          );
        }
        const { isLoading } = args.rowData;
        return isLoading ? (
          <LoadingSpinner className={`${baseClassName}__header-plus-icon`} />
        ) : (
          <TrashIcon className={`${baseClassName}-trash-icon`} onClick={() => handleRemoveTruck(args.rowData.id)} />
        );
      }
    })
  ];

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__header`}>
        <Button theme="1" label="Add Truck" icon={<PlusIcon />} disabled={state.isAdding} onClick={createAddTruckRow} />
      </div>
      <div className={`${baseClassName}__body`}>
        <AutoResizer>
          {({ width, height }) => (
            <Table
              fixed
              data={state.isAdding && !props.trucks.length ? addTruckRow : trucksWithState}
              frozenData={state.isAdding && props.trucks.length ? addTruckRow : []}
              width={width}
              height={height}
              columns={tableColumns}
            />
          )}
        </AutoResizer>
      </div>
    </div>
  );
}

DepartmentDetailSidebarTrucking.propTypes = {
  departmentId: PropTypes.string,
  trucks: PropTypes.array,
  setParentState: PropTypes.func,
  onLoadTrucks: PropTypes.func
};

export default React.memo(DepartmentDetailSidebarTrucking);
