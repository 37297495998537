import React, { useEffect, useReducer, useState } from 'react';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast/component';
import { useSelector } from 'react-redux';
import { selectDepartmentsById } from '@client/_blessed/store/entities';
import InvoicingRules from './component';
import reducer, {
  initialState,
  FETCH_RULE_GROUP_DATA_REQUEST,
  FETCH_RULE_GROUP_DATA_SUCCESS,
  FETCH_RULE_GROUP_DATA_ERROR
} from './reducer';

interface AccessorialRuleGroupAttributes {
  uuid: string;
  name: string;
  additionalFees: number;
  CNEEUuid?: string;
  deletedAt: string | null;
  createdAt: string | null;
}

interface Accessorial {
  uuid: string;
  accessorialRuleGroupUuid: string;
  feeType: string;
  createdAt: null;
  updatedAt: null;
  deletedAt: null;
  deletedByUuid: null;
}

export interface AccessorialRuleGroup {
  attributes: AccessorialRuleGroupAttributes;
  accessorials: Accessorial[];
}

function fetchARAccessorialFees(): any {
  const url = api('/ar-accessorial-fees?sort=true');
  const opts = { query: { include: [] } };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

// TODO: Replace `any` with correct type
function getActiveRuleGroup(cneeUUID: string): any {
  const url = api('/accessorial-rule-groups/active');
  const opts = { method: 'GET', query: { cneeUUID } };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

// TODO: Replace `any` with correct type
function getAvailableRuleGroups(cneeUUID: string): any {
  const url = api('/accessorial-rule-groups');
  const opts = { method: 'GET', query: { cneeUUID } };

  // @ts-ignore
  const result = request(url, opts); // TODO: Fix ts error on `opts` arg
  return result;
}

function fetchActiveAndAvailableData(cneeUUID: string) {
  const promises = Promise.all([getActiveRuleGroup(cneeUUID), getAvailableRuleGroups(cneeUUID)]).then(
    ([active, available]) => {
      const result = { active, available };
      return result;
    },
    (e) => {
      console.log({ e });
    }
  );
  return promises;
}

interface InvoicingRulesContainerProps {
  departmentId: string;
}

function InvoicingRulesContainer(props: InvoicingRulesContainerProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    activeRuleGroupUuid,
    activeRuleGroup,
    availableRuleGroups,
    isLoadingAvailableGroups,
    isLoadingActiveGroup,
    errorWhileFetching,
    errorWhileUpdating,
    errorWhileCreating
  } = state;

  const { departmentId } = props;
  const departmentsById = useSelector(selectDepartmentsById);
  const consignee = departmentsById?.[departmentId];
  const companyUuid = consignee?.companyUUID;
  const customer = departmentsById?.[companyUuid!];
  // TODO: make sure `department.type` === 'customer'

  const [arAccessorialFees, setARAccessorialFees] = useState([]);
  const [, setARAccessorialFeesError] = useState<React.SetStateAction<any>>(null);

  useEffect(() => {
    fetchARAccessorialFees().then(
      (d: any) => {
        setARAccessorialFees(d.data);
      },
      (e: any) => {
        raiseToast(
          <SystemToast
            type={SystemToast.Type.ERROR}
            message="Something went wrong while fetching the list of accessorial fees"
          />
        );

        console.log({ e });
        setARAccessorialFeesError(e);
      }
    );
  }, [departmentId]);

  useEffect(() => {
    dispatch({ type: FETCH_RULE_GROUP_DATA_REQUEST });
    const promise = fetchActiveAndAvailableData(departmentId);
    promise.then(
      (data) => {
        dispatch({ type: FETCH_RULE_GROUP_DATA_SUCCESS, data });
      },
      (error) => {
        raiseToast(
          <SystemToast
            type={SystemToast.Type.ERROR}
            message="Something went wrong while fetching the active and available rate types"
          />
        );
        dispatch({ type: FETCH_RULE_GROUP_DATA_ERROR, error });
        console.log({ error });
      }
    );
  }, [departmentId, activeRuleGroupUuid]);

  const invoicingRulesProps = {
    accessorialRuleGroup: activeRuleGroup,
    arAccessorialFees,
    availableRuleGroups,
    consignee,
    customer,
    isLoadingAvailableGroups,
    isLoadingActiveGroup,
    dispatch,
    errorWhileCreating,
    errorWhileUpdating,
    errorWhileFetching
  };

  return <InvoicingRules {...invoicingRulesProps} />;
}

export default InvoicingRulesContainer;
