import { type AccessorialRuleGroup } from './container';

export const FETCH_RULE_GROUP_DATA_REQUEST = 'FETCH_RULE_GROUP_DATA_REQUEST';
export const FETCH_RULE_GROUP_DATA_SUCCESS = 'FETCH_RULE_GROUP_DATA_SUCCESS';
export const FETCH_RULE_GROUP_DATA_ERROR = 'FETCH_RULE_GROUP_DATA_ERROR';
export const CREATE_CUSTOM_REQUEST = 'CREATE_CUSTOM_REQUEST';
export const CREATE_CUSTOM_SUCCESS = 'CREATE_CUSTOM_SUCCESS';
export const CREATE_CUSTOM_ERROR = 'CREATE_CUSTOM_ERROR';
export const UPDATE_ACTIVE_REQUEST = 'UPDATE_ACTIVE_REQUEST';
export const UPDATE_ACTIVE_SUCCESS = 'UPDATE_ACTIVE_SUCCESS';
export const UPDATE_ACTIVE_ERROR = 'UPDATE_ACTIVE_ERROR';
export const DELETE_ACTIVE_REQUEST = 'DELETE_ACTIVE_REQUEST';
export const DELETE_ACTIVE_SUCCESS = 'DELETE_ACTIVE_SUCCESS';
export const DELETE_ACTIVE_ERROR = 'DELETE_ACTIVE_ERROR';

export const initialState = {
  activeRuleGroupUuid: null,
  activeRuleGroup: null,
  isLoadingActiveGroup: true,
  availableRuleGroups: [],
  isLoadingAvailableGroups: true,
  errorWhileFetching: null,
  errorWhileUpdating: null,
  errorWhileCreating: null
};

function reducer(state: any, action: any) {
  const { type, data, error } = action;
  switch (type) {
    case FETCH_RULE_GROUP_DATA_REQUEST: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = true;
      updatedState.isLoadingAvailableGroups = true;

      return updatedState;
    }
    case FETCH_RULE_GROUP_DATA_SUCCESS: {
      const updatedState = { ...state };
      updatedState.errorWhileFetching = null;
      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;
      updatedState.activeRuleGroup = data.active?.data || null;
      updatedState.activeRuleGroupUuid = data.active?.data?.attributes?.uuid || null;
      updatedState.availableRuleGroups = data.available.data.filter(
        (x: AccessorialRuleGroup) => !x.attributes.deletedAt
      );

      return updatedState;
    }

    case FETCH_RULE_GROUP_DATA_ERROR: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;
      updatedState.errorWhileFetching = error;

      return updatedState;
    }

    case UPDATE_ACTIVE_REQUEST: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = true;
      updatedState.isLoadingAvailableGroups = true;
      updatedState.activeRuleGroup = null;

      return updatedState;
    }

    case UPDATE_ACTIVE_SUCCESS: {
      const { accessorialRuleGroup } = action;
      const updatedState = { ...state };
      updatedState.activeRuleGroup = accessorialRuleGroup;
      updatedState.activeRuleGroupUuid = accessorialRuleGroup?.attributes?.uuid;
      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;

      return updatedState;
    }
    case CREATE_CUSTOM_REQUEST: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = true;
      updatedState.isLoadingAvailableGroups = true;
      return updatedState;
    }
    case CREATE_CUSTOM_SUCCESS: {
      const updatedState = { ...state };
      updatedState.activeRuleGroupUuid = null;
      updatedState.activeRuleGroup = null;
      updatedState.isLoadingActiveGroup = true;
      updatedState.isLoadingAvailableGroups = true;

      return updatedState;
    }

    case CREATE_CUSTOM_ERROR: {
      const updatedState = { ...state };
      updatedState.errorWhileCreating = error;
      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;

      return updatedState;
    }

    case DELETE_ACTIVE_REQUEST: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = true;
      updatedState.isLoadingAvailableGroups = true;

      return updatedState;
    }

    case DELETE_ACTIVE_SUCCESS: {
      const updatedState = { ...state };

      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;
      updatedState.activeRuleGroup = null;
      updatedState.activeRuleGroupUuid = null;

      return updatedState;
    }

    case DELETE_ACTIVE_ERROR: {
      const updatedState = { ...state };
      updatedState.isLoadingActiveGroup = false;
      updatedState.isLoadingAvailableGroups = false;

      return updatedState;
    }

    default:
      return state;
  }
}

export default reducer;
