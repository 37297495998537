import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { retrieveBankingInfoByDepartmentUUID } from '../../../../state/resources/departments/actions';
import TextInput from '../../../TextInput';
import { updateDepartmentPrivateDetails } from '../../../../state/resources/department-private-details/actions';
import DropdownSelectInput from '../../../DropdownSelectInput';
import Button from '../../../Button';

import './style.css';
import { DepartmentPrivateDetailsAttributes } from './types';

const bankingTypes = [
  {
    label: 'Personal Checking',
    value: 'personal_checking'
  },
  {
    label: 'Business Checking',
    value: 'business_checking'
  }
];

function DepartmentDetailSidebarBanking(props: { departmentId: string }) {
  const [state, setState] = useState<{
    loggedIn: boolean;
    isLoading: boolean;
    isSaving: boolean;
    messages: null;
    errors: null;
    password: string;
    departmentPrivateDetail: DepartmentPrivateDetailsAttributes | undefined;
  }>({
    loggedIn: false,
    isLoading: true,
    isSaving: false,
    messages: null,
    errors: null,
    password: '',
    departmentPrivateDetail: undefined
  });
  const baseClassName = 'department-detail-sidebar-banking';

  let bankName: string | undefined;
  let bankType: string | undefined;
  let routingNumber: string | undefined;
  let bankAccount: string | undefined;
  let password: string = '';

  const id = state.departmentPrivateDetail && state.departmentPrivateDetail.uuid;

  const departmentPrivateDetails = state.departmentPrivateDetail;

  if (departmentPrivateDetails) {
    bankName = departmentPrivateDetails?.bankName;
    bankType = departmentPrivateDetails?.bankType;
    routingNumber = departmentPrivateDetails?.routingNumber;
    bankAccount = departmentPrivateDetails?.bankAccount;
  } else {
    bankName = '';
    routingNumber = '';
    bankAccount = '';
    bankType = '';
  }

  const bankTypeDropValues = useMemo(() => [bankingTypes.find((item) => item.value === bankType) || {}], [bankType]);

  const loadData = async () => {
    let errors;
    let departmentPrivateDetail: DepartmentPrivateDetailsAttributes | undefined;

    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        errors: null
      }));

      departmentPrivateDetail = await retrieveBankingInfoByDepartmentUUID(props.departmentId, password)();
    } catch (err: unknown) {
      if (typeof err === 'object' && err !== null && 'errors' in err) {
        errors = err.errors;
        console.error(errors);
      }
    } finally {
      setState((prevState) => ({
        ...prevState,
        departmentPrivateDetail,
        isLoading: false
      }));
    }
  };

  const handlePasswordSubmit = async (value: string) => {
    // TODO Move this to secret service
    if (value === 'drdray') {
      password = value;
      loadData();
      setState((prevState) => ({
        ...prevState,
        loggedIn: true,
        password: value
      }));
    }
  };

  const handleSave = async () => {
    const item = {
      type: 'departmentPrivateDetail',
      id,
      attributes: {
        bankName,
        bankType,
        routingNumber,
        bankAccount,
        departmentUuid: props.departmentId
      }
    };

    try {
      setState((prevState) => ({
        ...prevState,
        isSaving: true
      }));

      await updateDepartmentPrivateDetails([item], state.password)();
    } finally {
      setState((prevState) => ({
        ...prevState,
        isSaving: false
      }));
    }
  };

  const handleValueUpdate = async (value: string | typeof bankingTypes, { dataField }: { dataField: string }) => {
    switch (dataField) {
      case 'bankName':
        if (typeof value === 'string') {
          bankName = value;
        }
        break;
      case 'routingNumber':
        if (typeof value === 'string') {
          routingNumber = value;
        }
        break;
      case 'bankAccount':
        if (typeof value === 'string') {
          bankAccount = value;
        }
        break;
      case 'bankType':
        if (typeof value !== 'string') {
          bankType = value[0].value;
        }
        break;
      default:
        throw new Error('Unknown datafield');
    }
  };

  return (
    <form className={baseClassName}>
      {!state.loggedIn && state.isLoading ? (
        <TextInput type="password" label="Password" onSubmit={handlePasswordSubmit} />
      ) : (
        <>
          <TextInput
            label="Bank Name"
            dataId={id}
            dataField="bankName"
            dataResourceType="departmentPrivateDetail"
            value={bankName}
            onSubmit={handleValueUpdate}
          />
          <TextInput
            label="Bank Routing"
            dataId={id}
            dataField="routingNumber"
            dataResourceType="departmentPrivateDetail"
            value={routingNumber}
            onSubmit={handleValueUpdate}
          />
          <TextInput
            label="Bank Account"
            dataId={id}
            dataField="bankAccount"
            dataResourceType="departmentPrivateDetail"
            value={bankAccount}
            onSubmit={handleValueUpdate}
          />
          <DropdownSelectInput
            label="Bank Type"
            dropdownHandle
            options={bankingTypes}
            dataId={id}
            dataField="bankType"
            dataResourceType="departmentPrivateDetail"
            values={bankTypeDropValues}
            onChange={handleValueUpdate}
          />
          <Button
            className={`${baseClassName}__save-button`}
            loading={state.isSaving}
            theme="1"
            label="Save"
            onClick={handleSave}
          />
        </>
      )}
    </form>
  );
}

DepartmentDetailSidebarBanking.propTypes = {
  departmentId: PropTypes.string
};

export default React.memo(DepartmentDetailSidebarBanking);
