import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { capitalize } from 'lodash';
import Switch from 'react-switch';
import { TIME_ZONES } from '@drayalliance/types';
import EditableField from '../../../../../../EditableField';

import './style.css';

interface DayWindowProps {
  data: { open: string; close: string };
  dayPrefix: string;
  editable: boolean;
  isSameWeekdayHours?: boolean;
  showTimezones: boolean;
  timezone: TIME_ZONES;
  // eslint-disable-next-line no-unused-vars
  onChange: (...args: any) => any;
}

function DayWindow(props: DayWindowProps) {
  const className = 'department-detail-sidebar-delivery-day-window';
  const { dayPrefix, isSameWeekdayHours, onChange, editable, showTimezones } = props;
  const [available, setAvailable] = useState(!!(props.data.open && props.data.close));
  const [openTime, setOpenTime] = useState(props.data.open || '00:00');
  const [closeTime, setCloseTime] = useState(props.data.close || '00:00');

  const allTimes = [];

  const timezoneString = useMemo(() => moment.tz(props.timezone).format('z'), [props.timezone]);

  const normalizeTime = (time: string) => {
    if (time.length === 5) return `${time}:00`;
    return time;
  };

  const closeTimeHandler = async (val: string) => {
    await onChange({ ...props, open: normalizeTime(openTime), close: normalizeTime(val) });
    setCloseTime(val);
  };

  const openTimeHandler = async (val: string) => {
    await onChange({ ...props, open: normalizeTime(val), close: normalizeTime(closeTime) });
    setOpenTime(val);
  };

  const displayTime = (time: string) => {
    const timeSplitArray = time.split(':');
    if (timeSplitArray.length > 2) {
      timeSplitArray.pop();
      return timeSplitArray.join(':');
    }
    return time;
  };

  const interval = 15;
  for (let i = 0; i < 24; i += 1) {
    for (let j = 0; j < 60; j += interval) {
      let iString = `${i}`;
      if (iString.length === 1) iString = `0${i}`;
      let jString = `${j}`;
      if (jString.length === 1) jString = `0${j}`;
      const time = `${iString}:${jString}`;
      allTimes.push({ label: time, value: normalizeTime(time) });
    }
  }

  const setAvailableHandler = async (value: any) => {
    if (value) {
      await onChange({ ...props.data, open: '00:00:00', close: '00:00:00' });
    } else {
      onChange({ ...props.data, open: null, close: null });
      setAvailable(value);
    }
  };

  const dayPrefixString = isSameWeekdayHours ? dayPrefix : capitalize(dayPrefix);
  return (
    <div className={`${className}-wrapper`}>
      {editable ? (
        <div className={`${className}-available ${className}-item`}>
          <Switch
            onChange={setAvailableHandler}
            checked={available}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#24b248"
            offColor="#c6cbd4"
            width={32}
            height={16}
            className={`${className}-switch`}
          />
          <div className={`${className}-day-name`}>{dayPrefixString}</div>
        </div>
      ) : (
        <div className={`${className}-uneditable-item`}>
          <div className={`${className}-day-header`}>{dayPrefixString}</div>
          <div>{`${displayTime(openTime)} - ${displayTime(closeTime)}${
            showTimezones ? ` ${timezoneString}` : ''
          }`}</div>
        </div>
      )}
      {editable && (
        <div className={`${className}-time-section`}>
          <div className={`${className}-open ${className}-editable-item`}>
            {available && (
              <>
                <p className={`${className}-editble-field-header`}>Open</p>
                <EditableField
                  type="dropdown"
                  editable={props.editable}
                  className={`${className}-time-picker`}
                  value={openTime}
                  onChange={openTimeHandler}
                  inputProps={{
                    dropdownHandle: true,
                    closeOnSelect: true,
                    options: allTimes
                  }}
                  displayFormatter={(value: string) => value.slice(0, 5)}
                />
              </>
            )}
          </div>
          <div className={`${className}-close ${className}-editable-item`}>
            {available && (
              <>
                <p className={`${className}-editble-field-header`}>Close</p>
                <EditableField
                  type="dropdown"
                  editable={props.editable}
                  className={`${className}-time-picker`}
                  value={closeTime}
                  onChange={closeTimeHandler}
                  inputProps={{
                    dropdownHandle: true,
                    closeOnSelect: true,
                    options: allTimes
                  }}
                  displayFormatter={(value: string) => value.slice(0, 5)}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DayWindow;
