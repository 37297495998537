import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import SystemToast from '@client/components/SystemToast';
import { raiseToast } from '@client/components/Toaster';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingScreen from '@components/deprecatedTookit/LoadingScreen';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { formatDrayUsersDropdown } from '@client/utils/data-processing/dray-users';
import { updateShipperPreference } from '@client/state/resources/shipperPreferences/actions';
import TextArea from '@client/components/TextArea';
import {
  departmentRightSidebarTypesToUIMappings,
  departmentRightSidebarTypesUIDropdownOptions,
  importExportValues,
  importExportUIMappings,
  dropOrLiveToUIMappings,
  dropOrLiveValues,
  dropOrLiveUIDropdownOptions,
  drayAllianceDepartmentUUID,
  departmentRightSidebarShipperTypesToUIMappings,
  departmentRightSidebarShipperTypesUIDropdownOptions
} from '@client/constants';
import { updateDepartments } from '@client/state/resources/departments/actions';
import { formatCompaniesDropdown } from '@client/utils/data-processing/company';
import { setDepartmentRegion } from '@client/state/resources/regions/actions';
import { retrieveTerminalApptOptions } from '@client/state/resources/resource-field-options/actions';
import { isReserved } from '@client/utils/reserved-entities';
import { handleCustomError } from '@client/utils/errors';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { TIME_ZONES } from '@drayalliance/types';
import { useSelector } from 'react-redux';
import {
  fetchDepartmentById,
  selectDepartmentsById,
  selectDepartmentsLoading
} from '@client/_blessed/store/entities/departments';
import {
  fetchUsersForDepartment,
  selectUsersByDepartmentUuid,
  selectUsersByUuid,
  selectUsersLoadingByDepartmentUuid
} from '@client/_blessed/store/entities';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Checkbox from '../../../Checkbox';
import getNestedObject from '../../../../utils/getNestedObject';
import { appActionContext, appStateContext } from '../../../App/contexts';
import DropdownSelectInput from '../../../DropdownSelectInput';
import TextInput from '../../../TextInput';

import './style.css';

const getTimezoneWithLatLong = async (lat: number, long: number) => {
  try {
    const url = api(`/timezone?lat=${lat}&lng=${long}`);
    const response = await request(url);
    return response;
  } catch (err) {
    raiseToast(
      <SystemToast type={SystemToast.Type.ERROR} message={handleCustomError(err, 'Unable to fetch timezone')} />
    );
    throw err;
  }
};

interface DepartmentDetailSidebarInfoProps {
  departmentId: string;
  stopInstant?: Record<string, any>;
  // eslint-disable-next-line no-unused-vars
  optimisticallyRemoveDepartment: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onOrderUpdated?: (...args: any) => any;
  editable: boolean;
}

function DepartmentDetailSidebarInfo(props: DepartmentDetailSidebarInfoProps) {
  const dispatch = useAppDispatch();
  const appDispatch = useContext(appActionContext);
  const appState = useContext(appStateContext);
  const className = 'department-detail-sidebar-info';
  const companiesById = appState['company.id'];
  const featureFlags = useFlags();
  const { showTimezones } = featureFlags;

  const timezoneOptions = Object.values(TIME_ZONES).map((timeZone) => ({ label: timeZone, value: timeZone }));
  const { shipperTypeSegmentation } = useFlags();

  const departmentsLoading = useSelector(selectDepartmentsLoading);
  const departmentsById = useSelector(selectDepartmentsById);

  const usersLoading = useSelector(selectUsersLoadingByDepartmentUuid);
  const usersByDepartmentUuid = useSelector(selectUsersByDepartmentUuid);
  const usersByUuid = useSelector(selectUsersByUuid);

  useEffect(() => {
    let departmentPromise: ReturnType<ReturnType<typeof fetchDepartmentById>> | null = null;
    if (!departmentsLoading && departmentsById[props.departmentId] === undefined) {
      departmentPromise = dispatch(fetchDepartmentById({ departmentUuid: props.departmentId }));
    }

    let usersPromise: ReturnType<ReturnType<typeof fetchUsersForDepartment>> | null = null;
    if (!usersLoading && usersByDepartmentUuid[drayAllianceDepartmentUUID] === undefined) {
      usersPromise = dispatch(fetchUsersForDepartment(drayAllianceDepartmentUUID));
    }

    return () => {
      if (departmentPromise) {
        departmentPromise.abort();
      }
      if (usersPromise) {
        usersPromise.abort();
      }
    };
  }, [props.departmentId, usersLoading, departmentsLoading, usersByDepartmentUuid]);

  const department = departmentsById?.[props.departmentId];
  const drayUsers = usersByDepartmentUuid?.[drayAllianceDepartmentUUID];

  if (!department && !drayUsers) {
    return <LoadingScreen />;
  }

  const departmentType = department.type;
  const isCarrier = departmentType === 'carrier';
  const isTerminalDepartment = departmentType === 'terminal';
  const isCustomerDepartment = departmentType === 'customer';
  const isShippingLineDepartment = departmentType === 'shipping_line';
  const isShippingAddress = ['consignee', 'rail_yard', 'yard', 'terminal'].indexOf(departmentType) >= 0;
  const isChassisPoolDepartment = departmentType === 'chassis_pool';

  const shipperPreferences = appState['shipperPreferences.shipperId']
    ? appState['shipperPreferences.shipperId'][department.uuid]
    : null;

  const [state, setState] = useState({
    address: '',
    streetAddr1: isShippingAddress ? department.shippingAddress?.street1 : department.billingAddress?.street1 || '',
    isFetching: false,
    errors: null,
    addressLoading: false,
    isFetchingTerminalApptOptions: true
  });

  let invoiceNotificationRecipientsInput = '';

  const invoiceNotificationRecipientsIsDefined =
    shipperPreferences &&
    shipperPreferences.invoiceNotificationFromHubType === 'email' &&
    shipperPreferences.invoiceNotificationRecipients &&
    (shipperPreferences.invoiceNotificationRecipients.to || shipperPreferences.invoiceNotificationRecipients.cc);

  if (invoiceNotificationRecipientsIsDefined) {
    const { to, cc } = shipperPreferences.invoiceNotificationRecipients;

    if (to && cc) {
      invoiceNotificationRecipientsInput = to.concat(cc).join(', ');
    } else if (to) {
      invoiceNotificationRecipientsInput = to.join(', ');
    } else if (cc) {
      invoiceNotificationRecipientsInput = cc.join(', ');
    }
  }

  const [shipperPreference, setShipperPreference] = useState({
    data: {
      invoiceNotificationFromHubType: shipperPreferences && shipperPreferences.invoiceNotificationFromHubType,
      invoiceNotificationRecipients: shipperPreferences && shipperPreferences.invoiceNotificationRecipients
    },
    ui: {
      invoiceNotificationRecipientsInput
    }
  });

  const [chassisBadgeNameValid, setChassisBadgeNameValid] = useState(true);

  if (!isTerminalDepartment) {
    state.isFetchingTerminalApptOptions = false;
  }

  const handleChange = (address: string) => {
    setState((prevState) => ({
      ...prevState,
      streetAddr1: address
    }));
  };

  let regionsOptions = Object.values(appState['region.id']) as any[];
  regionsOptions = regionsOptions.map((region) => ({
    jsonApiData: region,
    name: region.attributes.name,
    id: region.id
  }));

  const computeSegment = (estimatedImportVolume: string | number | null) => {
    if (estimatedImportVolume !== null) {
      const vol = Number(estimatedImportVolume);
      if (vol < 120) return 'Micro';
      if (vol >= 120 && vol < 1200) return 'SMB';
      if (vol >= 1200) return 'Enterprise';
    }
    return 'Unknown';
  };

  const displayEstimatedImportVolume = (estimatedImportVolume: string | number | null) => {
    if (estimatedImportVolume !== null) {
      return Number(estimatedImportVolume);
    }
    return 'Unknown';
  };

  const updateField = async (value: any, { dataResourceType, dataId, dataField, dataFieldTypeName }: any) => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true
    }));

    let errors: any[] = [];

    const item: Record<string, any> = {
      type: dataResourceType,
      id: dataId
    };

    if (dataFieldTypeName === 'relationships') {
      item.relationships = {
        [dataField]: {
          data: {
            type: dataField,
            id: value
          }
        }
      };
    } else {
      item.attributes = {
        [dataField]: value
      };
    }

    try {
      if (
        dataResourceType === 'accountManager' &&
        item.attributes.accountManager !== shipperPreferences?.accountManager
      ) {
        const accountManagerValue = item.attributes.accountManager || null;
        await appDispatch(
          updateShipperPreference({ shipperUUID: props.departmentId, accountManager: accountManagerValue })
        );
      } else if (dataResourceType !== 'accountManager') {
        await updateDepartments([item])();
        await dispatch(
          fetchDepartmentById({ departmentUuid: props.departmentId, opts: { triggerLoadingState: false } })
        );
      }
    } catch (err) {
      errors = (err as any).errors;
      throw err;
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        isFetching: false,
        errors
      }));
    }
  };

  const handleDropdownChange = (value: any, dataProps: any) => {
    const fieldValue = value[0]?.value || null;
    if (dataProps) {
      return updateField(fieldValue, dataProps);
    }
  };

  const handleRegionChange = async (e: any, dataProps: any) => {
    await appDispatch(setDepartmentRegion(department, e[0].jsonApiData));
    handleDropdownChange(e, dataProps);
  };

  const handleSelect = async (address: any, typePrefix: any) => {
    setState((prevState) => ({
      ...prevState,
      addressLoading: true
    }));

    const errors: any[] = [];
    let streetAddr1 = '';

    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const timezone = await getTimezoneWithLatLong(latLng.lat, latLng.lng);

      const addressComponents: Record<string, string> = {};
      getNestedObject(results, [0, 'address_components']).forEach((addressComponent: any) => {
        addressComponents[addressComponent.types[0]] = addressComponent.long_name;
      });

      if (addressComponents.street_number && addressComponents.route) {
        streetAddr1 = `${addressComponents.street_number} ${addressComponents.route}`.trim();
      } else if (addressComponents.street_number) {
        streetAddr1 = addressComponents.street_number;
      } else if (addressComponents.route) {
        streetAddr1 = addressComponents.route;
      }

      // TODO: Use non JSON-API format/endpoints for updating departments
      const item: Record<string, any> = {
        type: 'department',
        id: department.uuid
      };

      item.attributes = {
        [`${typePrefix}Street1`]: streetAddr1,
        [`${typePrefix}City`]: addressComponents.neighborhood || addressComponents.locality,
        [`${typePrefix}State`]: addressComponents.administrative_area_level_1,
        [`${typePrefix}ZipCode`]: addressComponents.postal_code,
        [`${typePrefix}Country`]: addressComponents.country,
        mapResponse: { ...(department as any).mapResponse, [`${typePrefix}`]: addressComponents }
      };

      if (timezone.timeZoneId) {
        item.attributes.timezone = timezone.timeZoneId;
      } else {
        errors.push({
          message: 'Failed to fetch timezone'
        });
      }

      await updateDepartments([item])();
      await dispatch(fetchDepartmentById({ departmentUuid: props.departmentId, opts: { triggerLoadingState: false } }));
    } catch (err) {
      errors.push(err);
      throw err;
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        streetAddr1,
        errors,
        addressLoading: false
      }));
    }
  };

  const handleInputSubmit = (value: any, dataProps: any) => {
    if (!props.editable) return;

    /** Don't save when chassis badge is invalid */
    if (dataProps.dataField === 'badgeName' && !chassisBadgeNameValid) {
      return;
    }
    updateField(value, dataProps);
  };

  const handleInvoiceEmailChange = (e: any) => {
    const { value } = e.target;
    setShipperPreference((prevState) => ({
      ...prevState,
      ui: {
        invoiceNotificationRecipientsInput: value
      }
    }));
  };

  const handleInvoiceEmailSubmit = () => {
    let recipients: any[] = [];
    let recipientType = 'none';
    try {
      recipients = shipperPreference.ui.invoiceNotificationRecipientsInput.split(',');
      recipients = recipients.map((r) => r.trim()).filter((r) => r);

      if (recipients.length) {
        recipientType = 'email';
      }
    } catch (e) {
      console.error(e);
      return;
    }

    if (!_.isEqual(shipperPreference.data.invoiceNotificationRecipients?.to, recipients)) {
      appDispatch(
        updateShipperPreference({
          shipperUUID: props.departmentId,
          invoiceNotificationFromHubType: recipientType,
          invoiceNotificationRecipients: { to: recipients, cc: [] }
        })
      );

      setShipperPreference((prevState) => ({
        ...prevState,
        data: {
          invoiceNotificationFromHubType: recipients.length ? 'email' : 'none',
          invoiceNotificationRecipients: { to: recipients }
        }
      }));
    }
  };

  let addresses = null;

  const accountManager =
    shipperPreferences?.accountManager && usersByUuid ? usersByUuid[shipperPreferences?.accountManager] : null;

  if (isShippingAddress) {
    addresses = (
      <>
        <div className="department-detail-sidebar-info__group-title">Shipping Address</div>
        <div className="department-detail-sidebar-info__input-field-container">
          {!department.shippingAddress?.street1 &&
          (!department.mapResponse || !(department.mapResponse as any).shipping) ? (
            <span className="department-detail-sidebar-info__label__info">
              Please search for an address to enable the city selection
            </span>
          ) : (
            ''
          )}

          <div className="department-detail-sidebar-info__label">Street 1</div>
          <PlacesAutocomplete
            highlightFirstSuggestion
            debounce={500}
            value={state.streetAddr1}
            onChange={handleChange}
            onSelect={(address: any) => handleSelect(address, 'shipping')}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
              <div style={{ position: 'relative' }}>
                <input
                  {...getInputProps({
                    className: 'r-text-input department-detail-sidebar-info__input-field',
                    autoComplete: 'newPassword'
                  })}
                  disabled={!props.editable}
                />
                {suggestions.length ? (
                  <div className="department-detail-sidebar-info__autocomplete-dropdown-container">
                    {suggestions.map((suggestion: any) => {
                      const cl = suggestion.active ? 'suggestion_item suggestion_item--active' : 'suggestion_item';
                      // inline style for demonstration purpose
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            cl
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
          <div className="department-detail-sidebar-info__loading-zone">
            {state.addressLoading ? (
              <LoadingScreen />
            ) : (
              <>
                <TextInput
                  className="department-detail-sidebar-info__input-field"
                  label="Apt., suite, unit, building, floor, etc."
                  value={department.shippingAddress?.street2}
                  dataField="shippingStreet2"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                  disabled={!props.editable}
                />
                {department.mapResponse &&
                typeof department.mapResponse === 'object' &&
                department.mapResponse.shipping &&
                department.mapResponse.shipping.neighborhood &&
                department.mapResponse.shipping.locality ? (
                  <DropdownSelectInput
                    className="department-detail-sidebar-info__input-field"
                    dropdownHandle
                    values={[
                      {
                        label: department.shippingAddress?.city,
                        value: department.shippingAddress?.city
                      }
                    ]}
                    options={[
                      {
                        label: (department.mapResponse as any)?.shipping.neighborhood,
                        value: (department.mapResponse as any)?.shipping.neighborhood
                      },
                      {
                        label: (department.mapResponse as any)?.shipping.locality,
                        value: (department.mapResponse as any)?.shipping.locality
                      }
                    ]}
                    labelField="label"
                    valueField="value"
                    placeholder="Shipping City"
                    dataField="shippingCity"
                    label="Shipping City"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onChange={handleDropdownChange}
                  />
                ) : (
                  <TextInput
                    disabled
                    className="department-detail-sidebar-info__input-field"
                    label="City"
                    value={department.shippingAddress?.city}
                    dataField="shippingCity"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onSubmit={handleInputSubmit}
                  />
                )}
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="State"
                  value={department.shippingAddress?.state}
                  dataField="shippingState"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="Zip Code"
                  value={department.shippingAddress?.zip}
                  dataField="shippingZipCode"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="Country"
                  value={department.shippingAddress?.country}
                  dataField="shippingCountry"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                {showTimezones ? (
                  <DropdownSelectInput
                    className="department-detail-sidebar-info__input-field"
                    dropdownHandle
                    label="Timezone"
                    values={[
                      {
                        label: department.timezone,
                        value: department.timezone
                      }
                    ]}
                    labelField="label"
                    valueField="value"
                    options={timezoneOptions}
                    dataField="timezone"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onChange={handleDropdownChange}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </>
    );
  } else if (['customer', 'carrier', 'shipping_line'].indexOf(department.type) !== -1) {
    addresses = (
      <>
        <div className="department-detail-sidebar-info__group-title">Billing Address</div>
        <div className="department-detail-sidebar-info__input-field-container">
          {!department.billingAddress?.street1 &&
          (!department.mapResponse || !(department.mapResponse as any).billing) ? (
            <span className="department-detail-sidebar-info__label__info">
              Please search for an address to enable the city selection
            </span>
          ) : (
            ''
          )}
          <div className="department-detail-sidebar-info__label">Street 1</div>
          <PlacesAutocomplete
            highlightFirstSuggestion
            debounce={400}
            value={state.streetAddr1}
            onChange={handleChange}
            onSelect={(address: any) => handleSelect(address, 'billing')}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
              <div style={{ position: 'relative' }}>
                <input
                  {...getInputProps({
                    className: 'r-text-input department-detail-sidebar-info__input-field',
                    autoComplete: 'newPassword'
                  })}
                />
                {suggestions.length ? (
                  <div className="department-detail-sidebar-info__autocomplete-dropdown-container">
                    {suggestions.map((suggestion: any) => {
                      const cl = suggestion.active ? 'suggestion_item suggestion_item--active' : 'suggestion_item';
                      // inline style for demonstration purpose
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            cl
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
          <div className="department-detail-sidebar-info__loading-zone">
            {state.addressLoading ? (
              <LoadingScreen />
            ) : (
              <>
                <TextInput
                  className="department-detail-sidebar-info__input-field"
                  label="Apt., suite, unit, building, floor, etc."
                  value={department.billingAddress?.street2}
                  dataField="billingStreet2"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                  disabled={!props.editable}
                />
                {department.mapResponse &&
                typeof department.mapResponse === 'object' &&
                department.mapResponse.billing &&
                department.mapResponse.billing.neighborhood &&
                department.mapResponse.billing.locality ? (
                  <DropdownSelectInput
                    className="department-detail-sidebar-info__input-field"
                    dropdownHandle
                    values={[
                      {
                        label: department.billingAddress?.city,
                        value: department.billingAddress?.city
                      }
                    ]}
                    options={[
                      {
                        label: department.mapResponse.billing.neighborhood,
                        value: department.mapResponse.billing.neighborhood
                      },
                      {
                        label: department.mapResponse.billing.locality,
                        value: department.mapResponse.billing.locality
                      }
                    ]}
                    labelField="label"
                    valueField="value"
                    placeholder="Billing City"
                    dataField="billingCity"
                    label="Billing City"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onChange={handleDropdownChange}
                  />
                ) : (
                  <TextInput
                    disabled
                    className="department-detail-sidebar-info__input-field"
                    label="City"
                    value={department.billingAddress?.city}
                    dataField="billingCity"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onSubmit={handleInputSubmit}
                  />
                )}
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="State"
                  value={department.billingAddress?.state}
                  dataField="billingState"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="Zip Code"
                  value={department.billingAddress?.zip}
                  dataField="billingZipCode"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                <TextInput
                  disabled
                  className="department-detail-sidebar-info__input-field"
                  label="Country"
                  value={department.billingAddress?.country}
                  dataField="billingCountry"
                  dataResourceType="department"
                  dataId={department.uuid}
                  onSubmit={handleInputSubmit}
                />
                {showTimezones ? (
                  <DropdownSelectInput
                    className="department-detail-sidebar-info__input-field"
                    dropdownHandle
                    label="Timezone"
                    values={[
                      {
                        label: department.timezone,
                        value: department.timezone
                      }
                    ]}
                    labelField="label"
                    valueField="value"
                    options={timezoneOptions}
                    dataField="timezone"
                    dataResourceType="department"
                    dataId={department.uuid}
                    onChange={handleDropdownChange}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  let shipperInvoicePreference = null;

  if (department.type === 'customer') {
    shipperInvoicePreference = (
      <>
        <div className="department-detail-sidebar-info__group-title">Invoicing</div>
        <div className="department-detail-sidebar-info__input-field-container">
          <label className="r-text-input-label" htmlFor="invoiceEmailInput">
            Email addresses
          </label>
          <TextArea
            value={shipperPreference.ui.invoiceNotificationRecipientsInput}
            onChange={handleInvoiceEmailChange}
            onBlur={handleInvoiceEmailSubmit}
            placeholder="a@foo.com, b@bar.com"

            // onSubmit={handleInputSubmit}
          />
        </div>
      </>
    );
  }
  const companyId = department.companyUUID;
  const selectedRegionId = department.regionUUID;
  const selectedRegion = getNestedObject(appState, ['region.id', selectedRegionId!]);

  useEffect(() => {
    if (isTerminalDepartment) {
      (async () => {
        setState((prevState) => ({
          ...prevState,
          isFetchingTerminalApptOptions: true
        }));

        let terminalApptOptions: any;
        let terminalApptOptionsErrors: any;

        try {
          const response = await retrieveTerminalApptOptions()();

          // Generate dropdown options
          terminalApptOptions = response.data.map((item: any) => ({
            label: item.attributes.optionName,
            value: item.id
          }));
        } catch (err) {
          terminalApptOptionsErrors = (err as any).errors;
        } finally {
          setState((prevState) => ({
            ...prevState,
            terminalApptOptionsErrors,
            terminalApptOptions,
            isFetchingTerminalApptOptions: false
          }));
        }
      })();
    }
  }, [isTerminalDepartment]);

  return state.isFetchingTerminalApptOptions ? (
    <div className={className}>
      <LoadingScreen />
    </div>
  ) : (
    <div className={className}>
      <div className="department-detail-sidebar-info__group-title">General</div>
      {isCarrier && (
        <div>
          <Checkbox
            labelAnchor="left"
            label="Verified"
            checked={!!department.verified}
            dataField="verified"
            dataResourceType="department"
            dataId={department.uuid}
            onToggle={handleInputSubmit}
          />
        </div>
      )}
      <div className="department-detail-sidebar-info__multi-input-row">
        <div className="department-detail-sidebar-info__input-field-container">
          <TextInput
            className="department-detail-sidebar-info__input-field"
            label="Name"
            value={department.name}
            dataField="name"
            dataResourceType="department"
            dataId={department.uuid}
            onSubmit={handleInputSubmit}
            disabled={!props.editable}
          />
          <TextInput
            className="department-detail-sidebar-info__input-field"
            label="Nickname"
            value={department.nickname}
            dataField="nickname"
            dataResourceType="department"
            dataId={department.uuid}
            onSubmit={handleInputSubmit}
            disabled={!props.editable}
          />
          <DropdownSelectInput
            className="department-detail-sidebar-info__input-field"
            dropdownHandle
            values={[
              {
                label:
                  departmentRightSidebarTypesToUIMappings[
                    department.type as keyof typeof departmentRightSidebarTypesToUIMappings
                  ],
                value: department.type
              }
            ]}
            options={departmentRightSidebarTypesUIDropdownOptions}
            labelField="label"
            valueField="value"
            placeholder="Type"
            dataField="type"
            label="Type"
            dataResourceType="department"
            dataId={department.uuid}
            disabled={isReserved(department.uuid) || !props.editable}
            onChange={handleDropdownChange}
          />
          {(isTerminalDepartment || isShippingLineDepartment) && (
            <DropdownSelectInput
              className="department-detail-sidebar-info__input-field"
              dropdownHandle
              options={regionsOptions}
              labelField="name"
              valueField="id"
              placeholder="Region"
              values={[
                {
                  name: getNestedObject(selectedRegion, ['attributes', 'name']),
                  id: getNestedObject(selectedRegion, ['id'])
                }
              ]}
              dataField="region"
              label="Region"
              dataResourceType="department"
              dataFieldType="relationships"
              dataId={department.uuid}
              onChange={handleRegionChange}
              disabled={!props.editable}
            />
          )}
          {isChassisPoolDepartment && (
            <>
              <TextInput
                className={`department-detail-sidebar-info__input-field ${
                  !chassisBadgeNameValid ? 'department-detail-sidebar-info__input-field-invalid' : ''
                }`}
                label="Badge Name"
                value={department.badgeName}
                dataField="badgeName"
                dataResourceType="department"
                dataId={department.uuid}
                isValid={chassisBadgeNameValid}
                validationMessage="Must be smaller than 4 characters"
                onChange={(value) => {
                  if (value.length > 4) {
                    setChassisBadgeNameValid(false);
                    return;
                  }

                  setChassisBadgeNameValid(true);
                }}
                onSubmit={handleInputSubmit}
                disabled={!props.editable}
              />
              <TextInput
                className="department-detail-sidebar-info__input-field"
                label="Badge Color"
                value={department.badgeColor}
                dataField="badgeColor"
                dataResourceType="department"
                dataId={department.uuid}
                onSubmit={handleInputSubmit}
                disabled={!props.editable}
              />
            </>
          )}
          <TextInput
            className="department-detail-sidebar-info__input-field"
            label="Contact Number"
            value={department.contactNumber}
            dataField="contactNumber"
            dataResourceType="department"
            dataId={department.uuid}
            onSubmit={handleInputSubmit}
          />
          <DropdownSelectInput
            className="department-detail-sidebar-info__input-field"
            dropdownHandle
            disabled={isReserved(department.uuid) || !props.editable}
            values={[
              {
                label: getNestedObject(companiesById, [companyId!, 'attributes', 'name']),
                value: companyId
              }
            ]}
            options={formatCompaniesDropdown(companiesById)}
            labelField="label"
            valueField="value"
            placeholder="Company"
            // dataFeldType="relationships"
            dataField="companyUuid"
            label="Company"
            dataResourceType="department"
            dataId={department.uuid}
            onChange={handleDropdownChange}
          />
          {shipperTypeSegmentation && department.type === 'customer' && (
            <>
              <DropdownSelectInput
                className="department-detail-sidebar-info__input-field"
                dropdownHandle
                disabled={isReserved(department.uuid) || !props.editable}
                values={[
                  {
                    label: department.shipperType
                      ? departmentRightSidebarShipperTypesToUIMappings[
                          department.shipperType as keyof typeof departmentRightSidebarShipperTypesToUIMappings
                        ]
                      : null,
                    value: department.shipperType
                  }
                ]}
                options={departmentRightSidebarShipperTypesUIDropdownOptions}
                labelField="label"
                valueField="value"
                placeholder="Shipper Type"
                dataField="shipperType"
                label="Shipper Type"
                dataResourceType="department"
                dataId={department.uuid}
                onChange={handleDropdownChange}
              />
              <TextInput
                disabled
                className="department-detail-sidebar-info__input-field"
                label="Segment"
                value={computeSegment(department.estimatedImportVolume)}
                dataField="estimatedImportVolume"
                dataResourceType="department"
                dataId={department.uuid}
              />
              <TextInput
                className="department-detail-sidebar-info__input-field"
                label="Estimated Import Volume"
                value={displayEstimatedImportVolume(department.estimatedImportVolume)}
                dataField="estimatedImportVolume"
                dataResourceType="department"
                dataId={department.uuid}
                onSubmit={handleInputSubmit}
              />
            </>
          )}
          <DropdownSelectInput
            className="department-detail-sidebar-info__input-field"
            dropdownHandle
            values={[
              {
                label: accountManager ? `${accountManager?.firstName} ${accountManager?.lastName}` : '',
                value: accountManager?.uuid
              }
            ]}
            options={formatDrayUsersDropdown(drayUsers)}
            labelField="label"
            valueField="value"
            placeholder="Select Account Manager"
            dataField="accountManager"
            label="Account Manager"
            dataResourceType="accountManager"
            dataId={department.uuid}
            onChange={handleDropdownChange}
          />

          {isCustomerDepartment && (
            <DropdownSelectInput
              className="department-detail-sidebar-info__input-field"
              dropdownHandle
              disabled={isReserved(department.uuid) || !props.editable}
              values={[
                {
                  label: importExportUIMappings[getNestedObject(department, ['attributes', 'defaultOrderType'])],
                  value: getNestedObject(department, ['attributes', 'defaultOrderType'])
                }
              ]}
              options={Object.keys(importExportUIMappings).map((k) => ({
                label: importExportUIMappings[k],
                value: importExportValues[k as keyof typeof importExportValues]
              }))}
              labelField="label"
              valueField="value"
              placeholder="Default Order Type"
              dataField="defaultOrderType"
              label="Default Order Type"
              dataResourceType="department"
              dataId={department.uuid}
              onChange={async (value, dataProps) => {
                if (value[0].value === importExportValues.grayPoolImport) {
                  const { dataResourceType, dataId, dataFieldTypeName } = dataProps;
                  const dataField = 'defaultDeliveryType';
                  await handleDropdownChange([{ value: 'droppick', label: 'Drop & Pick' }], {
                    dataResourceType,
                    dataId,
                    dataFieldTypeName,
                    dataField
                  });
                }
                await handleDropdownChange(value, dataProps);
              }}
            />
          )}
          {isCustomerDepartment &&
            (getNestedObject(department, ['attributes', 'defaultOrderType']) !== importExportValues.grayPoolImport ? (
              <DropdownSelectInput
                className="department-detail-sidebar-info__input-field"
                dropdownHandle
                disabled={
                  isReserved(department.uuid) ||
                  !props.editable ||
                  getNestedObject(department, ['attributes', 'defaultOrderType']) === importExportValues.grayPoolImport
                }
                values={[
                  {
                    label: dropOrLiveToUIMappings[getNestedObject(department, ['attributes', 'defaultDeliveryType'])],
                    value: getNestedObject(department, ['attributes', 'defaultDeliveryType'])
                  }
                ]}
                options={dropOrLiveUIDropdownOptions.filter((option) => {
                  if (
                    getNestedObject(department, ['attributes', 'defaultOrderType']) ===
                    importExportValues.grayPoolImport
                  ) {
                    return option.label === dropOrLiveValues.droppick;
                  }
                  return true;
                })}
                labelField="label"
                valueField="value"
                placeholder="Default Delivery Type"
                dataField="defaultDeliveryType"
                label="Default Delivery Type"
                dataResourceType="department"
                dataId={department.uuid}
                onChange={handleDropdownChange}
              />
            ) : (
              <>
                <div className="r-text-input-label">Default Order Type</div>
                <div>Drop & Pick</div>
              </>
            ))}
        </div>
      </div>
      {addresses}
      {shipperInvoicePreference}
      {state.errors && (
        <p className={`${className}-error-message`}>{getNestedObject(state, ['errors', 0, 'message'])}</p>
      )}
    </div>
  );
}

export default React.memo(DepartmentDetailSidebarInfo);
